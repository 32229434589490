import { useEffect, useState, useCallback, useContext } from 'react';
import { useAtom } from 'jotai';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { getAutoOrderConfig } from '@/apiServices';

function useAutoOrderEntryForm({ FormAtoms, trajectories, accounts }) {
  const { showAlert } = useContext(ErrorContext);

  // Base fields
  const [selectedPair] = useAtom(FormAtoms.selectedPairAtom);
  const [selectedAccounts] = useAtom(FormAtoms.selectedAccountsAtom);
  const [selectedSide] = useAtom(FormAtoms.selectedSideAtom);
  const [baseQty] = useAtom(FormAtoms.baseQtyAtom);
  const [convertedQty] = useAtom(FormAtoms.convertedQtyAtom);
  const [quoteQty] = useAtom(FormAtoms.quoteQtyAtom);

  // Auto order fields
  const [duration, setDuration] = useAtom(FormAtoms.selectedDurationAtom);
  const [passiveness, setPassiveness] = useAtom(FormAtoms.passivenessAtom);
  const [alphaTilt, setAlphaTilt] = useAtom(FormAtoms.alphaTiltAtom);
  const [trajectory, setTrajectory] = useAtom(FormAtoms.trajectoryAtom);

  // Auto order state
  const [urgency, setUrgency] = useState(null);
  const [enableUrgency, setEnableUrgency] = useState(false);
  const [isAutoOrderFormLoading, setIsAutoOrderFormLoading] = useState(false);
  const [autoOrderConfig, setAutoOrderConfig] = useState(null);
  const [autoOrderDescription, setAutoOrderDescription] = useState(null);

  const fetchAutoOrderConfig = useCallback(async () => {
    try {
      const response = await getAutoOrderConfig(
        urgency,
        selectedAccounts.map((a) => accounts[a].exchangeName),
        selectedPair.id,
        baseQty || convertedQty
      );
      setAutoOrderConfig(response.config);
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Failed to fetch auto order config: ${error.message}`,
      });
    } finally {
      setIsAutoOrderFormLoading(false);
    }
  }, [urgency]);

  useEffect(() => {
    setEnableUrgency(
      selectedPair &&
        selectedAccounts.length > 0 &&
        selectedSide &&
        (baseQty || quoteQty)
    );
  }, [selectedPair, selectedAccounts, selectedSide, baseQty, quoteQty]);

  useEffect(() => {
    if (!enableUrgency) {
      setUrgency(null);
    }
  }, [enableUrgency]);

  useEffect(() => {
    if (enableUrgency && urgency) {
      setIsAutoOrderFormLoading(true);
      fetchAutoOrderConfig();
    }
  }, [enableUrgency, urgency]);

  useEffect(() => {
    Object.entries(autoOrderConfig || {}).forEach(([key, value]) => {
      if (key === 'engine_passiveness') setPassiveness(value);
      else if (key === 'alpha_tilt') setAlphaTilt(value);
      else if (key === 'trajectory') {
        const trajectory_id = Object.values(trajectories).find(
          (t) => t.name === value
        ).id;
        setTrajectory(trajectory_id);
      } else if (key === 'duration') setDuration(value);
      else if (key === 'description') setAutoOrderDescription(value);
    });
  }, [autoOrderConfig]);

  const configFields = {
    passiveness,
    setPassiveness,
    alphaTilt,
    setAlphaTilt,
    trajectory,
    setTrajectory,
    duration,
    setDuration,
  };

  return {
    urgency,
    setUrgency,
    enableUrgency,
    isAutoOrderFormLoading,
    autoOrderConfig,
    configFields,
    autoOrderDescription,
  };
}

export default useAutoOrderEntryForm;
