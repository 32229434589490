/* eslint-disable no-shadow */
import React, { useState, useEffect} from 'react';
import { Button, Box, Typography, TableContainer, TableCell, TableHead,
  TableRow, TextField, Modal, TableBody, MenuItem, Tooltip, IconButton,
  FormControl, Select, Table, InputLabel, FormControlLabel, Checkbox, LinearProgress, Stack,
  RadioGroup, Radio } from '@mui/material';
import { Delete } from '@mui/icons-material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import SettingsIcon from '@mui/icons-material/Settings';
import { Doughnut } from 'react-chartjs-2';
import { useTheme } from '@emotion/react';
import CachedIcon from '@mui/icons-material/Cached';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAtom } from 'jotai';
import { DateTime } from 'luxon';
import {
  openInNewTab,
  startRebalance,
  stopRebalance,
  checkRebalance,
  checkAccountRebalance,
  getPairPrice,
  cancelMultiOrder,
  stopScheduled,
  fetchOrderEntryFormData,
  getUserFavouritePairs,
  fetchMultiOrderDetailData,
} from '../../apiServices';
import PairSelector from '../dashboard/orderEntry/PairSelector';
import { msAndKs, truncate, BASEURL, removeFalsyAndEmptyKeys } from '../../util';
import {
  TimezoneAutoComplete,
  timeZoneNoOffset,
} from "../../shared/TimezoneUtil";
import { timezoneAtom } from '../../shared/hooks/useGlobalFormReducer';
import AccountRebalanceSubmitForm from './AccountRebalanceSettingsForm';

const RebalanceAssetsColumns = [
  { id: 'underlying', label: 'Underlying', minWidth: 70, align: 'left', style: { fontWeight: 'bold' } },
  { id: 'assetType', label: 'Asset Type', minWidth: 70, align: 'left', style: { fontWeight: 'bold' } },
  { id: 'targetWeight', label: 'Target Weight(%)', minWidth: 60, align: 'right', number: true, style: { fontWeight: 'bold' } },
  { id: 'currentWeight', label: 'Current Weight(%)', minWidth: 60, align: 'right', number: true },
  { id: 'targetNotional', label: 'Target Notional', minWidth: 70, align: 'right', number: true },
  { id: 'currentNotional', label: 'Current Notional', minWidth: 70, align: 'right', number: true },
  { id: 'targetQuantity', label: 'Target Quantity', minWidth: 70, align: 'right', number: true },
  { id: 'currentQuantity', label: 'Current Quantity', minWidth: 70, align: 'right', number: true },
  { id: 'proposedTradeNotional', label: 'Proposed Trade Notional', minWidth: 70, align: 'right', number: true },
  { id: 'proposedTradeQuantity', label: 'Proposed Trade Quantity', minWidth: 70, align: 'right', number: true },
  { id: 'diff', label: 'Difference', minWidth: 70, align: 'right', number: true },
];

const filteredAssets = (assets) => {
  return assets.filter(asset =>
    asset.symbol &&
    typeof asset.symbol === 'string' &&
    !['USDT', 'USD', 'USDC'].includes(asset.symbol)
  );
};

const formattedAssets = (assets) => {
  return assets.map(asset => ({
    ...asset,
    symbol: asset.symbol.replace(/-USDT|-USDC|-USD$/, ''),
  }));
};

function AccountRebalance({
  assetOrdering,
  balanceData,
  currentAssets,
  selectedAccount,
  selectedAccountFull,
  showAlert,
}) {
  const [targetWeights, setTargetWeights] = useState({});
  const [tolerance, setTolerance] = useState(1);
  const [rebalanceFrequency, setRebalanceFrequency] = useState("Hours");
  const [ourAssets, setOurAssets] = useState([]);
  const [rebalanceFrequencyValue, setRebalanceFrequencyValue] = useState(1);
  const [nextRebalance, setNextRebalance] = useState('');
  const [message, setMessage] = useState('');
  const [taskId, setTaskId] = useState([]);
  const [netBalance, setNetBalance] = useState({ assets: [] });
  const [counterAsset, setCounterAsset] = useState('USDT');
  const [currentPrices, setCurrentPrices] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [BalanceNotional, setBalanceNotional] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rebalanceData, setRebalanceData] = useState(null);
  const [childOrders, setChildOrders] = useState([]);
  const [isFloating, setIsFloating] = useState(false);
  const [pricesFetchedDuringRebalance, setPricesFetchedDuringRebalance] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenPairs, setTokenPairs] = useState([]);
  const [favouritePairs, setFavouritePairs] = useState([]);
  const [rebalanceProgress, setRebalanceProgress] = useState(0);
  const [positionTypes, setPositionTypes] = useState({});
  const [showCashRow, setShowCashRow] = useState(true);
  const [showStopConfirmation, setShowStopConfirmation] = useState(false);
  const [targetWeightEnabled, setTargetWeightEnabled] = useState({});
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [parentOrder, setParentOrder] = useState({});
  const [strategies, setStrategies] = useState([]);
  const [strategyParams, setStrategyParams] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState('');
  const [superStrategies, setSuperStrategies] = useState({});
  const [selectedStrategyParams, setSelectedStrategyParams] = useState({});
  const [selectedDuration, setSelectedDuration] = useState(15);
  const [passiveness, setPassiveness] = useState(0.02);
  const [discretion, setDiscretion] = useState(0.08);
  const [exposureTolerance, setExposureTolerance] = useState(0.1);
  const [alphaTilt, setAlphaTilt] = useState(0);
  const [isScheduled, setIsScheduled] = useState(false)
  const [startTime, setStartTime] = useState([new Date()]);
  const [setDateEnabled, setSetDateEnabled] = useState(false);
  const [durationStartDate, setDurationStartDate] = useState(null);
  const [timeZone, setTimeZone] = useAtom(timezoneAtom);
  const [expandedRows, setExpandedRows] = useState({});
  const [showPairSelectorModal, setShowPairSelectorModal] = useState(false);
  const [newRowIndex, setNewRowIndex] = useState(null);
  const [initialSettings, setInitialSettings] = useState({
    selectedStrategy,
    selectedDuration,
    alphaTilt,
    discretion,
    exposureTolerance,
    passiveness,
    selectedStrategyParams,
  });
  const [rebalanceMode, setRebalanceMode] = useState("Once");
  const theme = useTheme();

  const netBalanceAssets = netBalance.assets

  useEffect(() => {
    const fetchTokenPairsAndStrategies = async () => {
      try {
        const data = await fetchOrderEntryFormData();
        const pairs = data.pairs
          .filter((pair) => pair.quote === counterAsset)
          .map((pair) => ({
            base: pair.base,
            quote: pair.quote,
            label: pair.name,
            id: pair.name,
            is_contract: pair.is_contract,
            is_inverse: pair.is_inverse,
            exchanges: pair.exchanges,
            market_type: pair.market_type,
          }));
        setTokenPairs(pairs);

        const strategies = data.strategies.reduce((acc, strategy) => {
          if (strategy.name !== 'VWAP') acc[strategy.id] = strategy;
          return acc;
        }, {});
        setStrategies(strategies);

        setSelectedStrategy(data.strategies[0]?.id || '');
        setStrategyParams(data.strategy_params);
      } catch (error) {
        showAlert({ severity: 'error', message: `Failed to fetch token pairs or strategies: ${error.message}` });
      }
    };

    const loadInitialData = async () => {
      setLoading(true);
      await Promise.all([fetchTokenPairsAndStrategies()]);
      setLoading(false);
    };

    loadInitialData();
  }, [counterAsset]);

  const normalizeSymbol = (symbol) => {
    return `${symbol.replace(/-USDT|-USDC|-USD$/, '')}-USDT`;
  };

  const filterAssetsByMarketType = (assets, tokenPairs, marketType) => {
    return assets
      .filter(asset => {
        const assetIdentifier = asset.pair || asset.symbol;
        if (!assetIdentifier) return false;
        const normalizedSymbol = normalizeSymbol(assetIdentifier);
        const tokenPair = tokenPairs.find(pair => pair.label === normalizedSymbol);
        return tokenPair && tokenPair.market_type === marketType;
      })
      .map(asset => ({
        symbol: asset.pair || asset.symbol,
      }));
  };

  const fetchFavouritePairs = async () => {
    try {
      setLoading(true);
      const response = await getUserFavouritePairs();
      const { pairs } = response;

      const favouritePairs = pairs
        .filter(pair => {
          const [base, quote] = pair.split(/[-]/);
          return quote === counterAsset;
        })
        .reduce((acc, pair) => ({ ...acc, [pair]: true }), {});

      setFavouritePairs(favouritePairs);
    } catch (error) {
      showAlert({ severity: 'error', message: 'Failed to fetch favourite pairs.' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAccount && selectedAccount[0] && selectedAccount[0] !== 'All Accounts') {
      fetchFavouritePairs();
    }
  }, [selectedAccount, counterAsset]);

  const filteredSelectedAssets = filteredAssets(netBalanceAssets);
  const selectedAssets = netBalanceAssets.map(asset => ({
    ...asset,
    symbol: asset.symbol.replace(/-USDT|-USDC|-USD$/, '')
  }));

  const combinedAllAssets = [...selectedAssets, ...ourAssets];
  const combinedAssets = [...filteredSelectedAssets, ...ourAssets];
  const inProgressAssets = [...combinedAssets];
  Object.keys(targetWeights).forEach(symbolOrPair => {
    const trimmedSymbolOrPair = symbolOrPair.replace(/-USDT|-USDC|-USD$/, '');
    const existingAsset = combinedAssets.find(asset =>
      (asset.symbol === trimmedSymbolOrPair || asset.pair === trimmedSymbolOrPair)
    );

    if (!existingAsset) {
      inProgressAssets.push({
        symbol: trimmedSymbolOrPair,
        currentQuantity: 0,
        currentNotional: 0,
      });
    }
  });

  const filteredInprogressAssets = inProgressAssets.filter((asset, index, self) => {
    const symbolOrPair = asset.symbol || asset.pair;
    const normalizedSymbol = symbolOrPair.replace(/-USDT|-USDC|-USD$/, '')
    const suffixedSymbol = `${normalizedSymbol}-USDT`;
    const hasTargetWeight = Object.keys(targetWeights).includes(normalizedSymbol) ||
     Object.keys(targetWeights).includes(suffixedSymbol);
    const isUnique = index === self.findIndex((a) =>
      (a.symbol || a.pair).replace(/-USDT|-USDC|-USD$/, '') === normalizedSymbol);

    if (hasTargetWeight && isUnique) { /* empty */ }

    return hasTargetWeight && isUnique;
  });

  const combinedSymbols = combinedAllAssets.map(asset => asset.symbol);
  const getUniqueBases = (assets) =>
    Array.from(
      new Set(
        assets
          .filter((asset) => asset.symbol)
          .map((asset) => asset.symbol.replace(/:PERP(-USDT)?/, ""))
      )
    );

  const extendedCombinedAssets = [
    ...combinedAllAssets,
    ...filteredInprogressAssets.filter(
      (asset) => !combinedSymbols.includes(asset.symbol)
    ),
  ];

  const bases = getUniqueBases(combinedAllAssets);
  const basesInprogress = getUniqueBases(filteredInprogressAssets);
  const mapTokenPairsToAssets = (pairs, baseList) =>
    pairs
      .filter(
        (pair) =>
          pair.base &&
          baseList.includes(pair.base) &&
          pair.exchanges.includes(selectedAccountFull.exchange_name)
      )
      .map(({ base, exchanges, label, quote, is_contract, is_inverse, market_type }) => ({
        base,
        exchanges,
        label,
        quote,
        isContract: is_contract,
        isInverse: is_inverse,
        marketType: market_type,
      }));

  const underlyingAssets = mapTokenPairsToAssets(tokenPairs, bases);
  const underlyingAssetsInprogress = mapTokenPairsToAssets(tokenPairs, basesInprogress);

  const groupAssetsByBase = (assets) =>
    assets.reduce((acc, { base, label }) => {
      if (!acc[base]) {
        acc[base] = [];
      }
      acc[base].push(label);
      return acc;
    }, {});


  const groupedUnderlyingAssets = groupAssetsByBase(underlyingAssets);
  const groupedUnderlyingAssetsInprogress = groupAssetsByBase(underlyingAssetsInprogress);

  const spotAssetPairs = filterAssetsByMarketType(extendedCombinedAssets, tokenPairs, "spot");
  const perpAssetPairs = filterAssetsByMarketType(extendedCombinedAssets, tokenPairs, "perp");

  const formattedSpotAssetPairs = formattedAssets(spotAssetPairs);
  const formattedPerpAssetPairs = formattedAssets(perpAssetPairs);

  const normalizedSymbol = (symbol) => symbol.replace(/-USDT|-USDC|-USD$/, '');
  const spotSymbols = formattedSpotAssetPairs.map(pair => pair.symbol);
  const perpSymbols = formattedPerpAssetPairs.map(pair => pair.symbol);

  const allFormattedSymbols = new Set([...spotSymbols, ...perpSymbols]);
  const remainingTokenPairs = tokenPairs.filter(pair => {
    const normalizedLabel = normalizedSymbol(pair.label);
    return !allFormattedSymbols.has(normalizedLabel);
  });

  const filteredCombinedAssets = filteredAssets(combinedAssets);
  const calculateCurrentWeight = (currentNotional, totalCurrentNotional) => {
    const weight = (currentNotional / totalCurrentNotional) * 100;
    return weight.toFixed(2);
  };

  const trimSymbol = (symbol) => {
    if (typeof symbol === 'string') {
      return symbol.replace(/-USDT|-USDC|-USD$/, '');
    }
    return symbol;
  };

  const chartColors = [
    ...Object.values(theme.palette.exchangeBalance),
  ];

  const loadOrderData = async (order_id) => {
    try {
      const orderData = await fetchMultiOrderDetailData(order_id);
      const childOrders = orderData?.child_orders ?? [];
      const totalPctFilled = childOrders.reduce((sum, order) => sum + (order.pct_filled || 0), 0);
      const avgPctFilled = childOrders.length > 0 ? totalPctFilled / childOrders.length : 0;
      setRebalanceProgress(avgPctFilled);
    } catch (e) {
      showAlert({ severity: 'error', message: `Failed to fetch order details: ${e.message}` });
      setRebalanceProgress(0);
    }
  };

  const fetchRebalanceStatus = async () => {
    const account_id = selectedAccount?.[0];
    if (!account_id || account_id === 'All Accounts') {
      return;
    }

    try {
      setLoading(true);
      const data = await checkRebalance(account_id);

      if (data.is_rebalance_enabled === true) {
        setInProgress(true);
        setIsScheduled(false);
        setOrderId(data.parent_order_id);
        setTaskId(data.task_id);
        const currentTime = new Date().getTime();
        const timeStart = new Date(data.time_start).getTime();
        const duration = data.rebalance_settings.duration * 1000;
        const endTime = timeStart + duration;
        const timeLeft = endTime - currentTime;
        const remainingMinutes = timeLeft > 0 ? (timeLeft / 60000).toFixed(2) : 15;
        setSelectedDuration(remainingMinutes);
        setBalanceNotional(data.current_balance_notional);
        const simplifiedTargetWeights = Object.entries(data.target_weights).reduce((acc, [symbol, value]) => {
          acc[symbol] = value.targetWeight;
          return acc;
        }, {});

        setTargetWeights(simplifiedTargetWeights);
        setTolerance(data.tolerance)
        setNextRebalance(data.next_rebalance);
        setStartTime(data.time_start);
        setIsFloating(data.is_floating_enabled);
        setRebalanceMode(data.rebalance_mode);
        if (selectedAccount && selectedAccount[0]) {
          localStorage.removeItem(`isScheduled_${selectedAccount[0]}`);
        }
        if (selectedAccount && selectedAccount[0]) {
          localStorage.removeItem(`startTime_${selectedAccount[0]}`);
        }
        if (data.parent_order_id) {
          await loadOrderData(data.parent_order_id);
        }
      } else {
        setInProgress(false);
        setMessage('');
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchPairPrice = async (symbol) => {
    if (!symbol || ['USDT', 'USD', 'USDC'].includes(symbol)) {
      return null;
    }

    let exchangeName = selectedAccount?.[1] || '';
    if (exchangeName === 'All Accounts') {
      exchangeName = 'All Accounts';
    }

    const pair = symbol.includes(counterAsset) ? symbol : `${symbol}-${counterAsset}`;
    try {
      const result = await getPairPrice(pair, exchangeName);
      const fetchedPrice = result[pair] || 0;

      if (fetchedPrice === 0) {
        showAlert({ severity: 'error', message: `Price not found for asset: ${symbol}` });
      }

      setCurrentPrices((prev) => {
        const updatedPrices = { ...prev, [symbol]: fetchedPrice };
        return updatedPrices;
      });

      return fetchedPrice;
    } catch (error) {
      showAlert({ severity: 'error', message: `Failed to fetch price for asset: ${symbol}` });
      return 0;
    }
  };

  const fetchPricesForAssets = async () => {
    const assetsToFetch = inProgress ? filteredInprogressAssets : netBalance.assets;

    await Promise.all(
      assetsToFetch.map((asset) => fetchPairPrice(asset.symbol))
    );

  };

  const initializeNetBalance = (selectedAccountBalances) => {
    const netBalance = { assets: [] };


    Object.keys(selectedAccountBalances).forEach((symbol) => {
      if (['USDT', 'USD', 'USDC'].includes(symbol)) {
        return;
      }

      const assetBalances = selectedAccountBalances[symbol];
      const lastBalance = assetBalances[assetBalances.length - 1] || {};
      const currentPrice = currentPrices[symbol] || 0;

      const currentNotional = lastBalance.notional || 0;
      const currentQuantity = currentPrice > 0 ? currentNotional / currentPrice : 0;

      netBalance.assets.push({
        symbol,
        currentQuantity,
        currentNotional,
      });
    });

    return netBalance;
  };

  const handleStartDateChange = (value) => {
    const formattedDate = DateTime.fromMillis(value.ts).toFormat('EEE MMM dd yyyy HH:mm:ss ZZZZ');
    setDurationStartDate(value);
    setStartTime([formattedDate]);
  };

  const handleToleranceChange = (e) => {
    setTolerance(parseFloat(e.target.value) || 0);
  };

  const handleInputChange = (symbol, event) => {
    const value = event.target.value === '' ? '' : parseFloat(event.target.value);
    const normalizedSymbol = symbol.replace(/-USDT|-USDC|-USD$/, '');

    setTargetWeights((prev) => {
      const updatedWeights = { ...prev };
      if (value === '' || Number.isNaN(value)) {
        delete updatedWeights[normalizedSymbol];
      } else {
        updatedWeights[normalizedSymbol] = Math.abs(value);
      }
      return updatedWeights;
    });
  };

  const targetWeightsWithAssetType = Object.entries(targetWeights).reduce((acc, [symbol, weight]) => {
    const normalizedSymbol = normalizeSymbol(symbol);
    const tokenPair = tokenPairs.find(pair => pair.label === normalizedSymbol);

    const marketType = tokenPair ? tokenPair.market_type : 'unknown';

    acc[symbol] = {
      targetWeight: weight,
      marketType,
    };

    return acc;
  }, {});

  useEffect(() => {
    if (selectedAccount && balanceData[selectedAccount[0]]) {
      const initialNetBalance = initializeNetBalance(balanceData[selectedAccount[0]]);
      setNetBalance(initialNetBalance);
      fetchPricesForAssets(initialNetBalance.assets);

      const initialTargetWeightEnabled = initialNetBalance.assets.reduce((acc, asset) => {
        const normalizedSymbols = (symbol) => symbol.replace(/-USDT|-USDC|-USD$/, '');
        const normalizedSymbol = normalizedSymbols(asset.symbol);
        acc[normalizedSymbol] = !!asset.isPerp || true;
        return acc;
      }, {});

      setTargetWeightEnabled(initialTargetWeightEnabled);
    }
  }, [selectedAccount, balanceData]);

  useEffect(() => {
    if (selectedAccount && selectedAccount[0] !== 'All Accounts') {
      fetchRebalanceStatus();

      setTargetWeights({});
      setBalanceNotional([]);
      setTolerance(1);
      setIsFloating(false);
      setRebalanceFrequencyValue(1);
      setRebalanceFrequency("Hours");
      setCounterAsset('USDT');
      setOurAssets([]);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (inProgress && filteredInprogressAssets.length > 0 && !pricesFetchedDuringRebalance) {
      fetchPricesForAssets();
      setPricesFetchedDuringRebalance(true);
    }
  }, [inProgress, pricesFetchedDuringRebalance]);

  useEffect(() => {
    if (!inProgress) {
      setPricesFetchedDuringRebalance(false);
    }
  }, [inProgress]);


  const calculateProposedTrade = (targetWeight, currentNotional, currentPrice, totalNotional) => {
    if (targetWeight === undefined || targetWeight === null || targetWeight === ''
       || Number.isNaN(targetWeight) || currentPrice === 0) {
      return { proposedTradeNotional: 0, proposedTradeQuantity: 0, targetNotional: 0 };
    }
    if (targetWeight === 0) {
      return {
        targetNotional: 0,
        proposedTradeNotional: -currentNotional,
        proposedTradeQuantity: currentPrice > 0 ? -currentNotional / currentPrice : 0,
      };
    }
    const targetNotional = (targetWeight / 100) * BalanceNotional;
    const proposedTradeNotional = targetNotional - currentNotional;
    const proposedTradeQuantity = currentPrice > 0 ? proposedTradeNotional / currentPrice : 0;

    return { proposedTradeNotional, proposedTradeQuantity, targetNotional };
  };

  const calculateDiff = (targetWeight, currentWeight, targetNotional, currentNotional) => {
    if (targetWeight === 0) {
      return (-currentWeight).toFixed(2);
    }
    if (!targetWeight || targetWeight === '' || Number.isNaN(targetWeight)) {
      return '0.00';
    }
    if (!currentNotional || currentNotional === 0 || Number.isNaN(currentNotional)) {
      return targetWeight.toFixed(2);
    }
    const diff = (targetNotional / currentNotional - 1) * 100;
    return diff.toFixed(2) === '-0.00' ? '0.00' : diff.toFixed(2);
  };

  const handleRowCalculations = (asset) => {
    const fullSymbol = asset.symbol;
    const currentPrice = currentPrices[fullSymbol] || 0;
    const currentNotional = asset.currentNotional || 0;
    const totalCurrentNotional = combinedAssets.reduce((sum, asset) => sum + (asset.currentNotional || 0), 0);
    const currentWeight = calculateCurrentWeight(currentNotional, totalCurrentNotional);
    const normalizedSymbol = fullSymbol.replace(/-USDT|-USDC|-USD$/, '');
    const targetWeight = Object.prototype.hasOwnProperty.call(targetWeights, normalizedSymbol)
      ? parseFloat(targetWeights[normalizedSymbol])
      : undefined;    const { proposedTradeNotional, proposedTradeQuantity, targetNotional } = calculateProposedTrade(
      targetWeight,
      currentNotional,
      currentPrice,
      totalCurrentNotional
    );

    const diff = calculateDiff(targetWeight, currentWeight, targetNotional, currentNotional);

    return {
      currentPrice,
      currentWeight,
      targetNotional,
      currentNotional,
      proposedTradeNotional,
      proposedTradeQuantity,
      diff,
    };
  };

  const filteredCombinedAllAssets = filteredAssets(combinedAllAssets)
  const assetsToUse = inProgress ? inProgressAssets : combinedAllAssets;

  let totalCurrentNotional;

  if (inProgress) {
    totalCurrentNotional = assetsToUse.reduce((sum, asset) => {
      const symbol = asset.symbol || (asset.pair && asset.pair.split('-')[0]);
      const hasTargetWeight = Object.prototype.hasOwnProperty.call(targetWeights, symbol)
        && parseFloat(targetWeights[symbol]) !== 0;

      const isPerp = formattedPerpAssetPairs.some(pair => pair.symbol === symbol);
      const currentNotional = isPerp ? Math.abs(asset.currentNotional || 0) : (asset.currentNotional || 0);

      return hasTargetWeight ? sum + currentNotional : sum;
    }, 0);
  } else {
    totalCurrentNotional = filteredCombinedAllAssets.reduce((sum, asset) => {
      const symbol = asset.symbol || (asset.pair && asset.pair.split('-')[0]);

      const isPerp = formattedPerpAssetPairs.some(pair => pair.symbol === symbol);
      const currentNotional = isPerp ? Math.abs(asset.currentNotional || 0) : (asset.currentNotional || 0);

      return sum + currentNotional;
    }, 0);
  }

  const totalCurrentNotionalAll = combinedAllAssets.reduce((sum, asset) => {
    return sum + (asset.currentNotional || 0);
  }, 0);

  const totalTargetWeight = assetsToUse.reduce((sum, asset) => {
    const symbol = asset.symbol || (asset.pair && asset.pair.split('-')[0]);
    const targetWeight = Math.abs(parseFloat(targetWeights[symbol] || 0));
    return targetWeight ? sum + targetWeight : sum;
  }, 0).toFixed(2);

  let totalCurrentWeight;

  if (inProgress) {
    totalCurrentWeight = assetsToUse.reduce((sum, asset) => {
      const symbol = asset.symbol || (asset.pair && asset.pair.split('-')[0]);
      const hasTargetWeight = Object.prototype.hasOwnProperty.call(targetWeights, symbol)
        && parseFloat(targetWeights[symbol]) !== 0;

      if (!hasTargetWeight) {
        return sum;
      }

      const currentNotional = asset.currentNotional || 0;
      const totalCurrentNotional = combinedAllAssets.reduce((totalSum, a) => {
        return totalSum + (a.currentNotional || 0);
      }, 0);

      const currentWeight = currentNotional > 0 && totalCurrentNotional > 0
        ? (currentNotional / totalCurrentNotional) * 100
        : 0;

      return sum + currentWeight;
    }, 0).toFixed(2);
  } else {

    totalCurrentWeight = combinedAllAssets.reduce((sum, asset) => {
      const currentNotional = asset.currentNotional || 0;
      const totalCurrentNotional = combinedAllAssets.reduce((totalSum, a) => {
        return totalSum + (a.currentNotional || 0);
      }, 0);

      const currentWeight = currentNotional > 0 && totalCurrentNotional > 0
        ? (currentNotional / totalCurrentNotional) * 100
        : 0;
      return sum + currentWeight;
    }, 0).toFixed(2);
  }

  const totalProposedTradeNotional = assetsToUse.reduce((sum, asset) => {
    const baseSymbol = asset.pair ? asset.pair.split('-')[0] : asset.symbol;
    const fullSymbol = `${baseSymbol}-USDT`;
    const currentPrice = currentPrices[fullSymbol] || currentPrices[baseSymbol] || 0;
    const currentNotional = asset.currentNotional || 0;
    const normalizedSymbol = baseSymbol;
    const targetWeight = Object.prototype.hasOwnProperty.call(targetWeights, normalizedSymbol)
      ? parseFloat(targetWeights[normalizedSymbol])
      : NaN;

    const isPerp = formattedPerpAssetPairs.some(pair => pair.symbol === baseSymbol);
    if (Number.isNaN(targetWeight) || currentPrice === 0) {
      return sum;
    }

    let proposedTradeNotional = 0;
    if (targetWeight === 0) {
      proposedTradeNotional = -currentNotional;
    } else {
      const targetNotional = (targetWeight / 100) * BalanceNotional;

      proposedTradeNotional = isPerp
        ? Math.abs(targetNotional) - Math.abs(currentNotional)
        : targetNotional - currentNotional;
    }

    return sum + proposedTradeNotional;
  }, 0).toFixed(2);

  const totalTargetNotional = assetsToUse.reduce((sum, asset) => {
    const baseSymbol = asset.pair ? asset.pair.split('-')[0] : asset.symbol;
    const targetWeight = Object.prototype.hasOwnProperty.call(targetWeights, baseSymbol)
      ? parseFloat(targetWeights[baseSymbol])
      : NaN;

    if (Number.isNaN(targetWeight)) {
      return sum;
    }

    const isPerp = formattedPerpAssetPairs.some(pair => pair.symbol === baseSymbol);
    let targetNotional = targetWeight === 0
      ? 0
      : (targetWeight / 100) * BalanceNotional;

    if (isPerp) {
      targetNotional = Math.abs(targetNotional);
    }

    return sum + targetNotional;
  }, 0).toFixed(2);

  const hasTargetWeights = Object.values(targetWeights).some((weight) => weight && parseFloat(weight) !== 0);

  let totalDiff = 0;

  if (hasTargetWeights) {
    if (totalCurrentNotional === 0) {
      totalDiff = parseFloat(totalTargetNotional) || 0;
    } else {
      totalDiff = ((parseFloat(totalTargetNotional) / totalCurrentNotional) - 1) * 100;
    }
  }

  const formattedTotalDiff =
    typeof totalDiff !== 'number' || Number.isNaN(totalDiff) || totalDiff === null
      ? '0.00'
      : totalDiff.toFixed(2);

  const formattedTotalProposedTradeNotional = totalProposedTradeNotional === "-0.00" ? "0.00"
    : totalProposedTradeNotional;
  const totalCurrentNotionalAllFormatted = msAndKs(truncate(totalCurrentNotionalAll), 2);

  const handleBalanceNotionalChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setBalanceNotional(value);
  };

  const getAssetQuantityForCounterAsset = () => {
    if (selectedAccount && balanceData[selectedAccount[0]]) {
      const selectedAccountBalances = balanceData[selectedAccount[0]];
      const assetBalances = selectedAccountBalances[counterAsset];
      if (assetBalances && assetBalances.length > 0) {
        const lastBalance = assetBalances[assetBalances.length - 1];
        if (lastBalance.amount) {
          return parseFloat(lastBalance.amount).toFixed(2);
        } if (lastBalance.notional) {
          return parseFloat(lastBalance.notional).toFixed(2);
        }
      }
    }
    return '0.00';
  };

  const handleRebalance = async () => {
    setSubmitLoading(true);
    setMessage('');

    if (!selectedAccount || !selectedAccount[0]) {
      showAlert({ severity: 'error', message: 'No account selected. Please select an account to rebalance.' });
      setSubmitLoading(false);
      return;
    }

    try {
      const rebalanceStatus = await checkAccountRebalance(selectedAccount[0]);
      if (rebalanceStatus.status === 'in_progress') {
        showAlert({
          severity: 'error',
          message: `Rebalance already in progress for account ${selectedAccount[0]}. Please wait for it to complete.`,
        });
        setSubmitLoading(false);
        return;
      }
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Failed to check rebalance status for account ${selectedAccount[0]}. Please try again.`,
      });
      setSubmitLoading(false);
      return;
    }

    const counterAssetBalance = parseFloat(getAssetQuantityForCounterAsset()) || 0;
    const combinedAssets = [...filteredAssets(netBalance.assets), ...ourAssets];
    const totalTargetWeight = Object.values(targetWeights).reduce((total, weight) =>
      total + parseFloat(weight || 0), 0);

    if (Math.abs(totalTargetWeight) <= 0 || Math.abs(totalTargetWeight) > 100) {
      showAlert({ severity: 'error', message:
        'Total target weight must be greater than 0% and less than or equal to 100%.' });
      setSubmitLoading(false);
      return;
    }

    const invalidTargetWeights = Object.entries(targetWeights).filter(([symbol, weight]) =>
      parseFloat(weight) < -100 || parseFloat(weight) > 100);
    if (invalidTargetWeights.length > 0) {
      showAlert({ severity: 'error', message: 'Target weights must be between -100% and 100%.' });
      setSubmitLoading(false);
      return;
    }

    const checkTolerance = (currentWeight, targetWeight) => {
      const lowerBound = targetWeight * (1 - tolerance / 100);
      const upperBound = targetWeight * (1 + tolerance / 100);
      return lowerBound <= currentWeight && currentWeight <= upperBound;
    };

    const filteredAssetsWithTargetWeight = combinedAssets.filter((asset) => {
      const normalizedSymbol = (asset.symbol || asset.pair.split('-')[0]).replace(/-USDT$/, '');
      const currentNotional = asset.currentNotional || 0;
      const currentWeight = calculateCurrentWeight(currentNotional, BalanceNotional);
      const targetWeight = parseFloat(targetWeights[normalizedSymbol] || 0);
      const targetNotional = targetWeight * (BalanceNotional / 100);
      const diff = parseFloat(calculateDiff(targetWeight, currentWeight, targetNotional, currentNotional));

      if (Math.abs(diff) < tolerance) {
        return false;
      }

      return Object.prototype.hasOwnProperty.call(targetWeights, normalizedSymbol);
    });

    if (filteredAssetsWithTargetWeight.length === 0) {
      showAlert({ severity: 'info', message: 'All assets are within tolerance. No rebalancing needed.' });
      setSubmitLoading(false);
      return;
    }


    const child_orders = filteredAssetsWithTargetWeight.map((asset) => {
      const symbolOrPair = trimSymbol(asset.symbol || asset.pair);
      const pair = symbolOrPair.includes(counterAsset) ? symbolOrPair : `${symbolOrPair}-${counterAsset}`;
      const currentPrice = currentPrices[symbolOrPair] || currentPrices[pair] || 0;
      if (currentPrice === 0) {
        return {
          accounts: [selectedAccount[1]],
          pair,
          side: 'sell',
          base_asset_qty: 0,
        };
      }
      const targetWeight = parseFloat(targetWeights[symbolOrPair] || 0);
      const { proposedTradeNotional } = calculateProposedTrade(
        targetWeight,
        asset.currentNotional,
        currentPrice,
        totalCurrentNotional
      );

      let side;
      if (targetWeight < 0) {
        side = 'sell';
      } else if (proposedTradeNotional > 0) {
        side = 'buy';
      } else {
        side = 'sell';
      }

      const base_asset_qty = currentPrice > 0 ? Math.abs(proposedTradeNotional / currentPrice) : 0;
      return {
        accounts: [selectedAccount[1]],
        pair,
        side,
        base_asset_qty,
      };
    });

    const counterAssetCalculation = parseFloat(getAssetQuantityForCounterAsset());
    const totalCurrentNotionalAndCounterAsset = totalCurrentNotional + counterAssetCalculation;

    if (BalanceNotional > totalCurrentNotionalAndCounterAsset) {
      showAlert({ severity: 'error', message: `Balance notional (${BalanceNotional.toFixed(2)} ${counterAsset})
      exceeds the available balance (${totalCurrentNotionalAndCounterAsset.toFixed(2)} ${counterAsset}).` });
      setSubmitLoading(false);
      return;
    }

    const maxAllowedBalanceNotional = BalanceNotional * 1.05;
    if (isFloating && maxAllowedBalanceNotional > totalCurrentNotionalAndCounterAsset) {
      showAlert({
        severity: 'error',
        message: `Maximum floating balance notional (${maxAllowedBalanceNotional.toFixed(2)} ${counterAsset})
        exceeds the available balance (${totalCurrentNotionalAndCounterAsset.toFixed(2)} ${counterAsset}).`,
      });
      setSubmitLoading(false);
      return;
    }

    if (formattedTotalProposedTradeNotional > counterAssetBalance) {
      showAlert({ severity: 'error', message: 'Insufficient liquidity for rebalancing.' });
      setSubmitLoading(false);
      return;
    }
    const duration = selectedDuration * 60;
    const intervalMilliseconds = rebalanceFrequencyValue * {
      Minutes: 60 * 1000,
      Hours: 60 * 60 * 1000,
      Days: 24 * 60 * 60 * 1000,
      Weeks: 7 * 24 * 60 * 60 * 1000,
      Months: 30 * 24 * 60 * 60 * 1000
    }[rebalanceFrequency];

    const intervalTime = intervalMilliseconds / 1000;

    if (duration > intervalTime) {
      showAlert({ severity: 'error', message: `Duration exceeds the available execution time.
        Available time: ${duration} seconds.` });
      setSubmitLoading(false);
      return;
    }

    const rebalanceSettings = removeFalsyAndEmptyKeys({
      ...parentOrder,
      duration,
    });
    const formattedStartTime = DateTime.fromJSDate(new Date(startTime)).toISO();

    const rebalanceData = {
      rebalance_mode: rebalanceMode,
      rebalance_settings: rebalanceSettings,
      target_weights: targetWeightsWithAssetType,
      tolerance,
      initial_balance_notional: parseInt(BalanceNotional, 10),
      is_floating: isFloating,
      account_id: selectedAccount[0],
      exchange_name: selectedAccount[1],
      counter_asset: counterAsset,
      interval: rebalanceMode === "Set Frequency" ? intervalTime : 0,
      start_date: formattedStartTime,
    };
    setChildOrders(child_orders);
    setRebalanceData(rebalanceData);
    setShowConfirmation(true);
  };

  const confirmRebalance = async () => {
    if (!rebalanceData) {
      showAlert({ severity: 'error', message: 'Rebalance data not found.' });
      return;
    }

    setSubmitLoading(true);
    setShowConfirmation(false);
    try {
      const response = await startRebalance(rebalanceData);
      if (response.success) {
        showAlert({ severity: 'success', message: response.message });
        setTaskId(response.task_id);
        setOrderId(response.parent_order_id);
        if (response.task_id) {
          localStorage.setItem(`rebalance_task_id_${selectedAccount[0]}`, response.task_id);
        }
        if (response.message.includes("successfully scheduled")) {
          setIsScheduled(true);
          setStartTime(response.start_date)
        }
      } else {
        showAlert({ severity: 'error', message: response.message });
      }
    } catch (error) {
      showAlert({ severity: 'error', message: 'Error occurred during rebalance process.' });
    } finally {
      setTimeout(() => {
        setSubmitLoading(false);
        fetchRebalanceStatus();
      }, 30000);
    }
  };

  const handleStopRebalanceConfirmation = () => {
    setShowStopConfirmation(true);
  };

  const confirmStopRebalance = async () => {
    setLoading(true);
    setStopLoading(true);
    setShowStopConfirmation(false);

    try {
      const accountId = selectedAccount?.[0];
      if (!accountId) {
        showAlert({ severity: 'error', message: 'No account selected.' });
        return;
      }

      let cancelOrderSuccess = true;

      if (orderId) {
        try {
          const cancelOrderResponse = await cancelMultiOrder(orderId);
          if (cancelOrderResponse.message) {
            showAlert({ severity: 'success', message: 'Order canceled successfully.' });
          } else {
            showAlert({ severity: 'warning', message: 'Failed to cancel order. Order might be already complete.' });
            cancelOrderSuccess = false;
          }
        } catch (error) {
          showAlert({ severity: 'warning', message: 'Order cancellation failed. Order might already be complete.' });
          cancelOrderSuccess = false;
        }
      }

      if (!taskId) {
        await fetchRebalanceStatus();

        if (!taskId) {
          showAlert({ severity: 'error', message: 'Task ID not found. Unable to stop the rebalance task.' });
          return;
        }
      }

      const taskKey = isScheduled
        ? `rebalance_task_id_${accountId}`
        : `rebalance_task_id_${accountId}`;

      const savedTaskId = localStorage.getItem(taskKey);

      if (!savedTaskId) {
        showAlert({ severity: 'error', message: 'No task ID found for the selected account.' });
        return;
      }

      const stopRebalanceResponse = isScheduled
        ? await stopScheduled(savedTaskId)
        : await stopRebalance(taskId);

      if (stopRebalanceResponse.message) {
        showAlert({ severity: 'success', message: stopRebalanceResponse.message });
        if (isScheduled) {
          setIsScheduled(false);
        }
      } else {
        showAlert({ severity: 'error', message: 'Failed to stop rebalance.' });
      }
    } catch (error) {
      showAlert({ severity: 'error', message: 'Error while stopping the rebalance.' });
    } finally {
      setLoading(false);
      setStopLoading(false);
      fetchRebalanceStatus();
    }
  };


  const calculateRebalanceInterval = () => {
    if (!startTime || !nextRebalance) {
      console.warn("startTime or nextRebalance is missing.");
      return { calculatedFrequencyValue: 0, calculatedFrequencyUnit: "Minutes" };
    }

    const startTimeDate = new Date(startTime);
    const nextRebalanceDate = new Date(nextRebalance);
    const timeDifferenceInMs = nextRebalanceDate - startTimeDate;

    let calculatedFrequencyValue = 0;
    let calculatedFrequencyUnit = "Minutes";

    const timeInMinutes = timeDifferenceInMs / (1000 * 60);
    const timeInHours = timeDifferenceInMs / (1000 * 60 * 60);
    const timeInDays = timeDifferenceInMs / (1000 * 60 * 60 * 24);
    const timeInWeeks = timeDifferenceInMs / (1000 * 60 * 60 * 24 * 7);
    const timeInMonths = timeDifferenceInMs / (1000 * 60 * 60 * 24 * 30);

    if (timeInMinutes < 60) {
      calculatedFrequencyValue = Math.round(timeInMinutes);
      calculatedFrequencyUnit = "Minutes";
    } else if (timeInHours < 24) {
      calculatedFrequencyValue = Math.round(timeInHours);
      calculatedFrequencyUnit = "Hours";
    } else if (timeInDays < 7) {
      calculatedFrequencyValue = Math.round(timeInDays);
      calculatedFrequencyUnit = "Days";
    } else if (timeInWeeks < 4) {
      calculatedFrequencyValue = Math.round(timeInWeeks);
      calculatedFrequencyUnit = "Weeks";
    } else {
      calculatedFrequencyValue = Math.round(timeInMonths);
      calculatedFrequencyUnit = "Months";
    }

    return { calculatedFrequencyValue, calculatedFrequencyUnit };
  };

  useEffect(() => {
    if (inProgress) {
      const { calculatedFrequencyValue, calculatedFrequencyUnit } = calculateRebalanceInterval();
      setRebalanceFrequencyValue(calculatedFrequencyValue);
      setRebalanceFrequency(calculatedFrequencyUnit);
    }
  }, [inProgress, rebalanceData]);

  const calculateNextRebalance = () => {
    const baseDate = startTime ? new Date(startTime) : new Date();
    const multiplier = rebalanceFrequencyValue;
    let nextTime;

    const daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

    switch (rebalanceFrequency) {
    case 'Minutes':
      nextTime = new Date(baseDate.getTime() + multiplier * 60 * 1000);
      break;
    case 'Hours':
      nextTime = new Date(baseDate.getTime() + multiplier * 60 * 60 * 1000);
      break;
    case 'Days':
      nextTime = new Date(baseDate.getTime() + multiplier * 24 * 60 * 60 * 1000);
      break;
    case 'Weeks':
      nextTime = new Date(baseDate.getTime() + multiplier * 7 * 24 * 60 * 60 * 1000);
      break;
    case 'Months': {
      let tempDate = new Date(baseDate);
      for (let i = 0; i < multiplier; i += 1) {
        const daysToAdd = daysInMonth(tempDate.getFullYear(), tempDate.getMonth());
        tempDate = new Date(tempDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
      }
      nextTime = tempDate;
      break;
    }
    default:
      return
    }

    setNextRebalance(nextTime.toISOString());
  };

  useEffect(() => {
    if (!inProgress) {
      calculateNextRebalance();
    }
  }, [rebalanceFrequency, rebalanceFrequencyValue, startTime]);

  const getSymbolColor = (index) => {
    if (index >= 10) {
      return {
        color: 'rgb(0, 69, 25)',
        backgroundColor: 'rgb(0, 69, 25)',
      };
    }
    return {
      color: chartColors[index],
      backgroundColor: chartColors[index],
    };
  };

  const filterCombinedAllAssetsDoughnut = assetsToUse.filter(asset => {
    const symbolOrPair = asset.symbol || asset.pair;
    return symbolOrPair && !['USD', 'USDT', 'USDC'].includes(symbolOrPair);
  });

  const normalizedAssets = filterCombinedAllAssetsDoughnut.reduce((acc, asset) => {
    const symbolOrPair = (asset.symbol || asset.pair).replace(/-USDT|-USD|-USDC|-PERP$/, '');
    const hasTargetWeight = Object.keys(targetWeights).includes(symbolOrPair) && targetWeights[symbolOrPair] !== 0;

    if (!acc.find(a => a.normalizedSymbol === symbolOrPair) && hasTargetWeight) {
      acc.push({ ...asset, normalizedSymbol: symbolOrPair });
    }
    return acc;
  }, []);

  const doughnutDatatarget = {
    labels: normalizedAssets.map(asset => asset.normalizedSymbol),
    datasets: [
      {
        label: 'Target Weight',
        data: normalizedAssets.map(asset => {
          return targetWeights[asset.normalizedSymbol] || 0;
        }),
        backgroundColor: normalizedAssets.map((_, index) => getSymbolColor(index).backgroundColor),
      },
    ],
  };

  const handlePairChange = async (index, selectedPair) => {
    const updatedAssets = [...ourAssets];
    const newSymbol = selectedPair.split('-')[0];
    updatedAssets[index] = {
      ...updatedAssets[index],
      pair: selectedPair,
    };
    setOurAssets(updatedAssets);
    await fetchPairPrice(selectedPair);
    setTargetWeights((prevWeights) => ({
      ...prevWeights,
      [newSymbol]: prevWeights[newSymbol] || 0,
    }));
    setShowPairSelectorModal(false);
    setNewRowIndex(null);
  };

  const groupAssetsByPairBase = (assets) => {
    return assets.reduce((acc, asset) => {
      const symbolOrPair = asset.pair || asset.symbol;
      if (!symbolOrPair) return acc;
      const base = symbolOrPair.split(':')[0].split('-')[0];
      const perpPair = `${base}:PERP-USDT`;
      const spotPair = `${base}-USDT`;
      if (!acc[base]) {
        acc[base] = [];
      }
      if (!acc[base].includes(spotPair)) acc[base].push(spotPair);
      if (!acc[base].includes(perpPair)) acc[base].push(perpPair);

      return acc;
    }, {});
  };

  const handleAddRow = () => {
    const newAsset = { pair: '', targetWeight: '', currentNotional: 0 };
    setOurAssets((prevAssets) => [...prevAssets, newAsset]);
    setNewRowIndex(ourAssets.length);
    setShowPairSelectorModal(true);
  };
  const SymbolOurAssets = ourAssets.map((asset) => {
    const { pair, ...rest } = asset;

    const normalizedSymbol = pair.includes(':') ? pair : `${pair.replace(/-USDT|-USDC|-USD$/, '')}-USDT`;

    return {
      symbol: normalizedSymbol,
      ...rest,
    };
  });

  const groupedUnderlyingOurAssets = groupAssetsByPairBase(ourAssets);

  const handleRemoveRow = (childSymbol) => {
    const updatedAssets = ourAssets.filter((asset) => asset.pair !== childSymbol);
    const normalizedSymbol = childSymbol.replace(/-USDT|-USDC|-USD$/, '');
    setTargetWeights((prevWeights) => {
      const { [normalizedSymbol]: _, ...remainingWeights } = prevWeights;
      return remainingWeights;
    });
    setOurAssets(updatedAssets);
  };

  const selectedAccountData = [selectedAccountFull];
  const transformedAccountData = {
    [selectedAccountFull.account_name]: {
      displayName: `${selectedAccountFull.exchange_name} - ${selectedAccountFull.account_name}`,
      exchangeName: selectedAccountFull.exchange_name,
      id: selectedAccountFull.account_id,
      name: selectedAccountFull.account_name
    }
  };
  const transformedBalanceData = {
    [selectedAccountFull.account_id]: {
      account_id: selectedAccountFull.account_id,
      account_name: selectedAccountFull.account_name,
      assets: selectedAccountFull.assets,
      exchange_name: selectedAccountFull.exchange_name,
      timestamp_millis: selectedAccountFull.timestamp_millis,
      user_id: selectedAccountFull.user_id,
      username: selectedAccountFull.username,
    }
  }
  const inProgressOrIsScheduled = inProgress || isScheduled
  const isRebalanceButtonDisabled = loading || submitLoading || inProgress || isScheduled || totalTargetNotional === '0.00';
  const isStopButtonDisabled = loading || stopLoading || !inProgressOrIsScheduled;

  const getRebalanceButtonText = () => {
    if (submitLoading) return 'Starting...';
    if (inProgress || isScheduled) return 'Rebalance in Progress';
    return 'Rebalance Now';
  };

  const getStopButtonText = () => {
    if (stopLoading) return 'Stopping...';
    return 'Stop Rebalance';
  };

  const getFormattedTotalDiffColor = (diff) => {
    if (diff > 0) return 'green';
    if (diff < 0) return 'red';
    return '#fff';
  };


  const calculateCashCurrentNotional = () => {
    const selectedAccountBalances = balanceData[selectedAccount[0]];
    if (!selectedAccountBalances) return 0;
    const cashAssets = ['USDT', 'USDC', 'USD'];
    return cashAssets.reduce((total, asset) => {
      const assetData = selectedAccountBalances[asset];
      if (assetData && assetData.length > 0) {
        return total + (assetData[0].notional || 0);
      }
      return total;
    }, 0);
  };

  const cashCurrntNotional = calculateCashCurrentNotional();
  const cashTargetWeight = 100 - totalTargetWeight;
  const reverseTotalProposedTradeNotional = -totalProposedTradeNotional;
  const cashTargetNotional = cashCurrntNotional + reverseTotalProposedTradeNotional;

  useEffect(() => {
    if (!inProgress && totalCurrentNotional) {
      setBalanceNotional(parseFloat(totalCurrentNotional).toFixed(2));
    }
  }, [totalCurrentNotional, inProgress]);

  const handleClearFields = () => {
    if (selectedAccount && selectedAccount[0]) {
      localStorage.removeItem(`targetWeights_${selectedAccount[0]}`);
    }
    if (selectedAccount && selectedAccount[0]) {
      localStorage.removeItem(`startTime_${selectedAccount[0]}`);
    }
    setTargetWeights({});
    setBalanceNotional(totalCurrentNotional.toFixed(2));
    setTolerance(1);
    setSelectedDuration(15);
    setIsFloating(false);
    setRebalanceFrequencyValue(1);
    setRebalanceFrequency("Hours");
    setCounterAsset('USDT');

    setPositionTypes({});
    setOurAssets([]);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      const loadData = async () => {
        if (selectedAccount?.[0] === 'All Accounts') {
          return;
        }

        try {
          setLoading(true);
          await fetchRebalanceStatus();
        } catch (error) {
          showAlert({ severity: 'info', message: 'Rebalance not in progress.' });
        } finally {
          setLoading(false);
        }
      };

      loadData();
    }, 1500);

    return () => {
      clearTimeout(handler);
    };
  }, [selectedAccount]);

  useEffect(() => {
    if (inProgress && orderId) {
      loadOrderData(orderId);
    }
  }, [inProgress, orderId]);

  useEffect(() => {
    let progressInterval;

    const fetchProgressPeriodically = async () => {
      if (inProgress && orderId) {
        try {
          await loadOrderData(orderId);
        } catch (error) {
          console.error("Error fetching progress:", error);
        }
      }
    };

    if (inProgress) {
      progressInterval = setInterval(fetchProgressPeriodically, 15000);
    } else {
      clearInterval(progressInterval);
    }

    return () => {
      clearInterval(progressInterval);
    };
  }, [inProgress, orderId]);

  useEffect(() => {
    const updatedPositionTypes = {};
    Object.entries(targetWeights).forEach(([symbolOrPair, weight]) => {
      updatedPositionTypes[symbolOrPair] = weight >= 0 ? 'long' : 'short';
    });
    setPositionTypes(updatedPositionTypes);
  }, [targetWeights]);

  const handleCheckboxChange = (symbol) => {
    setTargetWeightEnabled((prev) => {
      const isEnabled = !prev[symbol];
      const updatedEnabled = { ...prev, [symbol]: isEnabled };
      setTargetWeights((prevWeights) => {
        const updatedWeights = { ...prevWeights };
        if (!isEnabled) {
          delete updatedWeights[symbol];
        }
        return updatedWeights;
      });

      return updatedEnabled;
    });
  };

  const handleOpenSettings = () => {
    setInitialSettings({
      selectedStrategy,
      alphaTilt,
      discretion,
      exposureTolerance,
      passiveness,
      selectedStrategyParams,
    });

    setIsSettingsOpen(true);
  };

  const handleSaveSettings = () => {
    setParentOrder((prevParentOrder) => ({
      ...prevParentOrder,
      strategy: selectedStrategy,
      alpha_tilt: alphaTilt,
      schedule_discretion: discretion,
      exposure_tolerance: exposureTolerance,
      engine_passiveness: passiveness,
      strategy_params: selectedStrategyParams,
    }));

    setInitialSettings({
      selectedStrategy,
      alphaTilt,
      discretion,
      exposureTolerance,
      passiveness,
      selectedStrategyParams,
    });

    setIsSettingsOpen(false);
  };

  const handleCloseSettings = () => {
    setSelectedStrategy(initialSettings.selectedStrategy);
    setAlphaTilt(initialSettings.alphaTilt);
    setDiscretion(initialSettings.discretion);
    setExposureTolerance(initialSettings.exposureTolerance);
    setPassiveness(initialSettings.passiveness);
    setSelectedStrategyParams(initialSettings.selectedStrategyParams);

    setParentOrder((prevParentOrder) => ({
      ...prevParentOrder,
      strategy: initialSettings.selectedStrategy,
      alpha_tilt: initialSettings.alphaTilt,
      schedule_discretion: initialSettings.discretion,
      exposure_tolerance: initialSettings.exposureTolerance,
      engine_passiveness: initialSettings.passiveness,
      strategy_params: initialSettings.selectedStrategyParams,
    }));

    setIsSettingsOpen(false);
  };

  const multiOrderFormProps = {
    strategies,
    strategyParams,
    selectedStrategy,
    setSelectedStrategy,
    selectedDuration,
    selectedStrategyParams,
    setSelectedStrategyParams,
    setSelectedDuration,
    passiveness,
    setPassiveness,
    discretion,
    setDiscretion,
    exposureTolerance,
    setExposureTolerance,
    superStrategies,
    showAlert,
    alphaTilt,
    setAlphaTilt,
    submitLoading,
  };

  useEffect(() => {
    setParentOrder((prevParentOrder) => ({
      ...prevParentOrder,
      strategy: selectedStrategy,
      alpha_tilt: alphaTilt,
      schedule_discretion: discretion,
      exposure_tolerance: exposureTolerance,
      engine_passiveness: passiveness,
      strategy_params: selectedStrategyParams,
    }));
  }, [
    selectedStrategy,
    alphaTilt,
    discretion,
    exposureTolerance,
    passiveness,
    selectedStrategyParams,
  ]);

  useEffect(() => {
    if (selectedAccount && selectedAccount[0]) {
      const savedWeights = localStorage.getItem(`targetWeights_${selectedAccount[0]}`);
      if (savedWeights) {
        setTargetWeights(JSON.parse(savedWeights));
      } else {
        setTargetWeights({});
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedAccount && selectedAccount[0]) {
      localStorage.setItem(`targetWeights_${selectedAccount[0]}`, JSON.stringify(targetWeights));
    }
  }, [targetWeights, selectedAccount]);

  useEffect(() => {
    if (selectedAccount && selectedAccount[0]) {
      const savedTimeStart = localStorage.getItem(`startTime_${selectedAccount[0]}`);
      if (savedTimeStart) {
        try {
          const parsedTime = JSON.parse(savedTimeStart);
          const sanitizedTime = Array.isArray(parsedTime)
            ? parsedTime.map((time) => new Date(time))
            : [new Date(parsedTime)];
          setStartTime(sanitizedTime);
        } catch (error) {
          console.error('Error parsing saved startTime:', error);
          setStartTime([new Date()]);
        }
      } else {
        setStartTime([new Date()]);
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedAccount && selectedAccount[0]) {
      try {
        const sanitizedStartTime = Array.isArray(startTime)
          ? startTime.map((time) => (time instanceof Date ? time : new Date(time)))
          : [new Date(startTime)];
        localStorage.setItem(
          `startTime_${selectedAccount[0]}`,
          JSON.stringify(sanitizedStartTime.map((time) => time.toISOString()))
        );
      } catch (error) {
        console.error('Error saving startTime to localStorage:', error);
      }
    }
  }, [startTime, selectedAccount]);

  useEffect(() => {
    if (selectedAccount && selectedAccount[0]) {
      const savedIsScheduled = localStorage.getItem(`isScheduled_${selectedAccount[0]}`);
      if (savedIsScheduled !== null) {
        try {
          setIsScheduled(JSON.parse(savedIsScheduled));
        } catch (error) {
          console.error("Error parsing saved isScheduled:", error);
          setIsScheduled(false);
        }
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (selectedAccount && selectedAccount[0]) {
      try {
        localStorage.setItem(`isScheduled_${selectedAccount[0]}`, JSON.stringify(isScheduled));
      } catch (error) {
        console.error("Error saving isScheduled to localStorage:", error);
      }
    }
  }, [isScheduled, selectedAccount]);

  const formatDateTime = (time) => {
    const date = new Date(time);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const isoDate = new Date(startTime).toISOString();
  const formattedIsoDate = formatDateTime(isoDate)

  useEffect(() => {
    let scheduleCheckInterval;
    const checkSchedule = () => {
        if (startTime) {
            const currentTime = new Date();
            const scheduledTime = new Date(formattedIsoDate);
            if (currentTime > scheduledTime) {
                setIsScheduled(false);
            }
        }
    };
    if (isScheduled) {
        scheduleCheckInterval = setInterval(checkSchedule, 5000);
    } else {
        clearInterval(scheduleCheckInterval);
    }
    return () => clearInterval(scheduleCheckInterval);
  }, [isScheduled, startTime]);

  useEffect(() => {
    let fetchTimeout;
    const fetchStatusWithDelay = async () => {
        fetchTimeout = setTimeout(async () => {
            try {
                await fetchRebalanceStatus();
            } catch (error) {
                console.error("Failed to fetch rebalance status:", error);
            }
        }, 60000);
    };
    if (isScheduled !== undefined) {
        fetchStatusWithDelay();
    }
    return () => clearTimeout(fetchTimeout);
  }, [isScheduled]);

  let content;
  if (!inProgress && !isScheduled) {
    content = (
      <>
        <Tooltip arrow title="Click to add a new asset row for rebalancing">
          <Button
            color="primary"
            disabled={inProgress || isScheduled}
            sx={{ marginLeft: '30px', marginTop: '8px' }}
            variant="contained"
            onClick={handleAddRow}
          >
            Add row
          </Button>
        </Tooltip>
        {showPairSelectorModal && (
          <Modal
            aria-describedby="pair-selector-modal-description"
            aria-labelledby="pair-selector-modal"
            open={showPairSelectorModal}
            onClose={() => {
              setShowPairSelectorModal(false);
              setNewRowIndex(null);
            }}
          >
            <Box
              sx={{
                backgroundColor: '#333',
                padding: '20px',
                borderRadius: '5px',
                margin: 'auto',
                marginTop: '10%',
                width: '400px',
              }}
            >
              <Typography id="pair-selector-modal" sx={{ color: '#fff' }} variant="h6">
                Select a Pair
              </Typography>
              <PairSelector
                multiOrder
                accounts={transformedAccountData}
                balances={transformedBalanceData}
                favourites={favouritePairs}
                pairs={remainingTokenPairs}
                selectedAccounts={selectedAccountData.map((acc) => acc.account_name)}
                selectedPairName=""
                setFavourites={setFavouritePairs}
                setSelectedPair={(pair) => handlePairChange(newRowIndex, pair.label)}
                showAlert={showAlert}
              />
            </Box>
          </Modal>
        )}
      </>
    );
  } else if (inProgress) {
    content = (
      <Box
        sx={{
          backgroundColor: '#333',
          padding: '10px',
          borderRadius: '5px',
          marginBottom: '20px',
          width: '250px',
          marginLeft: '30px',
          marginTop: '8px',
        }}
      >
        <Typography sx={{ color: '#fff' }} variant="body1">
          Rebalance is in progress.
        </Typography>
        <Typography sx={{ color: '#fff' }} variant="body2">
          {rebalanceMode === 'Once' ? (
            <div>
              <strong>Rebalance Mode:</strong> Once
            </div>
          ) : (
            <div>
              <strong>Next Rebalance:</strong> {formatDateTime(nextRebalance)}
            </div>
          )}
        </Typography>
        <Typography align="center" marginTop="5px" sx={{ color: '#fff' }} variant="body2">
          Rebalance Progress: {rebalanceProgress.toFixed(2)}%
          </Typography>
            <LinearProgress
              sx={{ height: '10px', borderRadius: '5px', backgroundColor: '#444', marginBottom: '10px' }}
              value={rebalanceProgress}
              variant="determinate"
            />
            <Button
              color="primary"
              sx={{ marginTop: '10px' }}
              variant="contained"
              onClick={() => openInNewTab(`${BASEURL}/multi_order/${orderId}`)}
            >
              View Order
            </Button>

      </Box>
    );
  } else if (isScheduled) {
    content = (
      <Box
        sx={{
          backgroundColor: '#333',
          padding: '10px',
          borderRadius: '5px',
          marginBottom: '20px',
          width: '250px',
          marginLeft: '30px',
          marginTop: '8px',
        }}
      >
        <Typography sx={{ color: '#fff' }} variant="body1">
          Rebalance is scheduled.
        </Typography>
        <Typography sx={{ color: '#fff' }} variant="body2">
          Please wait for the scheduled start time.
        </Typography>
        <Typography sx={{ color: '#fff' }} variant="body2">
          Start time: {formattedIsoDate}
        </Typography>
      </Box>
    );
  }

  return loading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <ScaleLoader color="#f39c12" />
    </Box>
  ) : (
    <Box sx={{ padding: '5px', color: '#fff' }}>
      <Box sx={{ display: 'flex', marginBottom: '5px' }}>
        <Typography variant="h6">Rebalance Portfolio</Typography>
        <Tooltip arrow title="Clear all fields">
          <IconButton
            color="primary"
            sx={{
              color: '#f39c12',
              borderRadius: '50%',
              marginLeft: '10px',
              marginTop: '-7px',
            }}
            onClick={handleClearFields}
          >
            <CachedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>
        <TextField
          autoComplete="off"
          disabled={inProgress || isScheduled}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: 0,
            style: {
              backgroundColor: '#2c2c2c',
              color: '#fff',
              padding: '5px',
            }
          }}
          label="Tolerance(%)"
          sx={{ marginRight: '10px', width: '90px' }}
          type="text"
          value={tolerance}
          onChange={!inProgress || !isScheduled ? handleToleranceChange : null}
        />
        <TextField
          autoComplete="off"
          disabled={inProgress || isScheduled}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: 0,
            style: {
              backgroundColor: '#2c2c2c',
              color: '#fff',
              padding: '5px',
            }
          }}
          label={`Balance Notional (${counterAsset})`}
          sx={{ marginRight: '10px', width: '150px' }}
          type="text"
          value={BalanceNotional}
          onChange={!inProgress || !isScheduled ? handleBalanceNotionalChange : null}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isFloating}
              color="primary"
              disabled={inProgress || isScheduled}
              onChange={(e) => setIsFloating(e.target.checked)}
            />
          }
          label="Enable Floating Balance Notional (+5%/-5%)"
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          autoComplete="off"
          disabled={inProgress || isScheduled}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            min: 0,
            style: {
              backgroundColor: '#2c2c2c',
              color: '#fff',
              padding: '5px',
            }
          }}
          label="Duration(mins)"
          sx={{ width: '100px' }}
          type="text"
          value={selectedDuration}
          onChange={!inProgress || !isScheduled ? (e) => setSelectedDuration(parseInt(e.target.value, 10)) : null}
        />
        <Stack alignItems="center" direction="row">
          <Checkbox
            checked={setDateEnabled}
            disabled={inProgress || isScheduled}
            onChange={(e) => setSetDateEnabled(e.target.checked)}
          />
          <Typography variant="body1">Set Date</Typography>
        </Stack>
      </Box>
      <Box>
        {setDateEnabled && !inProgress && (
          <Box sx={{ marginTop: 1 }}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <Stack direction="row" spacing={1}>
                <DateTimePicker
                  disablePast
                  ampm={false}
                  label={`Time Start (${timeZoneNoOffset(timeZone)})`}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      sx={{
                        input: { backgroundColor: '#2c2c2c', color: '#fff' },
                      }}
                    />
                  )}
                  value={durationStartDate}
                  onChange={(value) => handleStartDateChange(value)}
                />
                <TimezoneAutoComplete
                  sx={{ width: '15%' }}
                  value={timeZone}
                  onChange={(tz) => setTimeZone(tz)}
                />
              </Stack>
            </LocalizationProvider>
          </Box>
          )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '5px' }}>
        {!inProgress && (
          <Box sx={{ marginBottom: '20px' }}>
            <Typography sx={{ marginBottom: '10px' }} variant="h6">
              Set Schedule
            </Typography>

            <FormControl>
              <RadioGroup
                row
                sx={{ justifyContent: 'flex-start' }}
                value={rebalanceMode}
                onChange={(e) => setRebalanceMode(e.target.value)}
              >
                <FormControlLabel
                  control={<Radio disabled={inProgress || isScheduled} />}
                  label="Once"
                  value="Once"
                />
                <FormControlLabel
                  control={<Radio disabled={inProgress || isScheduled} />}
                  label="Set Frequency"
                  value="Set Frequency"
                />
              </RadioGroup>
            </FormControl>
            {rebalanceMode === "Set Frequency" && (
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <TextField
                  autoComplete="off"
                  disabled={inProgress || isScheduled}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    min: 0,
                    style: {
                      backgroundColor: '#2c2c2c',
                      color: '#fff',
                      padding: '5px',
                    }
                  }}
                  label="Frequency Value"
                  sx={{ width: '100px', marginRight: '10px' }}
                  type="text"
                  value={rebalanceFrequencyValue}
                  onChange={(e) => setRebalanceFrequencyValue(parseInt(e.target.value, 10))}
                />
                <FormControl sx={{ width: '120px' }}>
                  <InputLabel id="rebalance-frequency-unit-label">Time Unit</InputLabel>
                  <Select
                    disabled={inProgress || isScheduled}
                    label="Time Unit"
                    labelId="rebalance-frequency-unit-label"
                    value={rebalanceFrequency}
                    onChange={(e) => setRebalanceFrequency(e.target.value)}
                  >
                    <MenuItem value="Minutes">Minutes</MenuItem>
                    <MenuItem value="Hours">Hours</MenuItem>
                    <MenuItem value="Days">Days</MenuItem>
                    <MenuItem value="Weeks">Weeks</MenuItem>
                    <MenuItem value="Months">Months</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '-5px', marginBottom: '-5px' }}>
        <Typography sx={{ color: '#fff' }} variant="body2">
        Next Rebalance: {formatDateTime(nextRebalance)}
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={showCashRow}
              color="primary"
              onChange={(e) => setShowCashRow(e.target.checked)}
            />
          }
          label="Show Cash Row"
          sx={{ marginLeft: '5px' }}
        />
        <IconButton color="primary" sx={{ marginLeft: 'auto' }} onClick={handleOpenSettings}>
          <SettingsIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <TableContainer sx={{
            width: '65%',
            maxHeight: (
              !inProgress ? filteredCombinedAssets.length : filteredInprogressAssets.length
            ) >
              5
                ? '423px'
                : 'auto',
            overflowY: (
              !inProgress ? filteredCombinedAssets.length : filteredInprogressAssets.length
            ) >
              5
                ? 'auto'
                : 'visible',
          }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {RebalanceAssetsColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{ color: '#fff', border: '1px solid #333', minWidth: column.minWidth, textAlign: column.align }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {Object.entries(!inProgress ? groupedUnderlyingAssets : groupedUnderlyingAssetsInprogress).map(
              ([baseSymbol, childSymbols]) => {
                const childCalculations = childSymbols.map((symbol) => {
                  const normalizedSymbol = symbol.includes(':') ? symbol : symbol.replace(/-USDT|-USDC|-USD$/, '');
                  const asset = [...filteredCombinedAssets, ...filteredInprogressAssets].find(
                    (a) => a.symbol === normalizedSymbol
                  );

                  if (!asset) {
                    return {
                      currentPrice: 0,
                      currentWeight: 0,
                      targetNotional: 0,
                      currentNotional: 0,
                      proposedTradeNotional: 0,
                      proposedTradeQuantity: 0,
                      diff: 0,
                    };
                  }

                  const {
                    currentPrice,
                    currentWeight,
                    targetNotional,
                    currentNotional,
                    proposedTradeNotional,
                    proposedTradeQuantity,
                    diff,
                  } = handleRowCalculations(asset, currentPrices, BalanceNotional);
                  const targetQuantity = Math.abs(targetNotional / currentPrice).toFixed(2)
                  const currentQuantity = Math.abs(currentNotional / currentPrice).toFixed(2)

                  return {
                    currentPrice,
                    currentWeight,
                    targetNotional,
                    currentNotional,
                    targetQuantity,
                    currentQuantity,
                    proposedTradeNotional,
                    proposedTradeQuantity,
                    diff,
                  };
                });

                const parentCalculations = childCalculations.reduce(
                  (totals, child) => {
                    return {
                      currentPrice: totals.currentPrice + (Number(child.currentPrice) || 0),
                      currentWeight: totals.currentWeight + (Number(child.currentWeight) || 0),
                      targetNotional: totals.targetNotional + (Number(child.targetNotional) || 0),
                      currentNotional: totals.currentNotional + (Number(child.currentNotional) || 0),
                      targetQuantity: totals.targetQuantity + (Number(child.targetQuantity) || 0),
                      currentQuantity: totals.currentQuantity + (Number(child.currentQuantity) || 0),
                      proposedTradeNotional: totals.proposedTradeNotional + (Number(child.proposedTradeNotional) || 0),
                      proposedTradeQuantity: totals.proposedTradeQuantity + (Number(child.proposedTradeQuantity) || 0),
                      diff: 0,
                    };
                  },
                  {
                    currentPrice: 0,
                    currentWeight: 0,
                    targetNotional: 0,
                    currentNotional: 0,
                    targetQuantity: 0,
                    currentQuantity: 0,
                    proposedTradeNotional: 0,
                    proposedTradeQuantity: 0,
                    diff: 0,
                  }
                );
                const parentDiff = (
                  (parentCalculations.targetNotional / parentCalculations.currentNotional - 1) * 100
                ).toFixed(2);
                const finalParentDiff = parentDiff === '-0.00' ? '0.00' : parentDiff;
                parentCalculations.diff = finalParentDiff;
                const parentDiffStyle = {};
                if (parentCalculations.diff > 0) {
                  parentDiffStyle.color = 'green';
                } else if (parentCalculations.diff < 0) {
                  parentDiffStyle.color = 'red';
                }
                const getTargetWeightInputWithButtons = (asset) => {
                  const symbolOrPair = asset.symbol ? trimSymbol(asset.symbol) : asset.pair;
                  const positionType =
                    positionTypes[symbolOrPair] || (targetWeights[symbolOrPair] < 0 ? "short" : "long");
                  const isPerp = formattedPerpAssetPairs.some(pair => pair.symbol === symbolOrPair);

                  const handlePositionTypeChange = (type) => {
                    setPositionTypes((prev) => ({
                      ...prev,
                      [symbolOrPair]: type,
                    }));
                    const currentWeight = parseFloat(targetWeights[symbolOrPair] || 0);
                    const adjustedWeight = type === "long" ? Math.abs(currentWeight) : -Math.abs(currentWeight);
                    setTargetWeights((prev) => ({
                      ...prev,
                      [symbolOrPair]: adjustedWeight,
                    }));
                  };

                  return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        autoComplete="off"
                        disabled={!targetWeightEnabled[symbolOrPair] || inProgress || isScheduled}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          min: 0,
                          max: 100,
                          style: {
                            backgroundColor: "#2c2c2c",
                            color: "#fff",
                            padding: "5px",
                          },
                        }}
                        type="text"
                        value={targetWeights[symbolOrPair] === undefined || targetWeights[symbolOrPair] === ''
                          ? ''
                          : Math.abs(targetWeights[symbolOrPair])}
                        onChange={(e) => handleInputChange(symbolOrPair, e)}
                      />
                      {isPerp && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "15px",
                            marginRight: "-10px",
                          }}
                        >
                          <Tooltip title="Set position to Long">
                            <span>
                              <Button
                                color="success"
                                disabled={!targetWeightEnabled[symbolOrPair] || inProgress || isScheduled}
                                size="small"
                                sx={{
                                  minWidth: "24px",
                                  height: "24px",
                                  padding: "2px",
                                  fontSize: "12px",
                                }}
                                variant={positionType === "long" ? "contained" : "outlined"}
                                onClick={() => handlePositionTypeChange("long")}
                              >
                                <TrendingUpIcon fontSize="inherit" />
                              </Button>
                            </span>
                          </Tooltip>
                          <Tooltip title="Set position to Short">
                            <span>
                              <Button
                                color="error"
                                disabled={!targetWeightEnabled[symbolOrPair] || inProgress || isScheduled}
                                size="small"
                                sx={{
                                  marginTop: "3px",
                                  minWidth: "24px",
                                  height: "24px",
                                  padding: "2px",
                                  fontSize: "12px",
                                }}
                                variant={positionType === "short" ? "contained" : "outlined"}
                                onClick={() => handlePositionTypeChange("short")}
                              >
                                <TrendingDownIcon fontSize="inherit" />
                              </Button>
                            </span>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  );
                };
                const renderTableCellContent = (column, assetOrBaseSymbol, diff, diffStyle, isParent = false, childCalculation = null) => {
                  const normalizedSymbol = isParent
                    ? assetOrBaseSymbol
                    : assetOrBaseSymbol.symbol.replace(/-USDT|-USDC|-USD$/, '');

                  const isPerp = !isParent && formattedPerpAssetPairs.some(pair => pair.symbol === normalizedSymbol);

                  switch (column.id) {
                    case 'underlying': {
                      return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {isParent ? (
                            <Typography>{`${normalizedSymbol}`}</Typography>
                          ) : (
                            <>
                              {!inProgress && (
                                <Tooltip
                                  arrow
                                  title={
                                    targetWeightEnabled[normalizedSymbol]
                                      ? 'Enabled - Asset will be included in rebalancing'
                                      : 'Disabled - Asset will not be included in rebalancing'
                                  }
                                >
                                  <Checkbox
                                    checked={targetWeightEnabled[normalizedSymbol] || false}
                                    onChange={() => handleCheckboxChange(normalizedSymbol)}
                                  />
                                </Tooltip>
                              )}
                              <Typography>{trimSymbol(assetOrBaseSymbol.symbol)}</Typography>
                            </>
                          )}
                        </Box>
                      );
                    }
                    case 'assetType': {
                      return isParent ? (
                        <span />
                      ) : (
                        <span>
                          {normalizedSymbol.includes(':') ? 'perp' : 'spot'}
                        </span>
                      );
                    }
                    case 'targetWeight':
                      return isParent ? (
                        <>
                          {childSymbols
                            .map((childSymbol) => {
                              const normalizedChildSymbol = childSymbol.replace(/-USDT|-USDC|-USD$/, '');
                              if (targetWeights[normalizedChildSymbol] && typeof targetWeights[normalizedChildSymbol] === 'number') {
                                return targetWeights[normalizedChildSymbol];
                              }
                              return 0;
                            })
                            .reduce((sum, weight) => sum + weight, 0)
                            .toFixed(2)}
                        </>
                      ) : (
                        getTargetWeightInputWithButtons(assetOrBaseSymbol)
                      );
                    case 'currentWeight': {
                      if (isParent) {
                        const displayValue = Math.abs(parentCalculations.currentWeight).toFixed(2);
                        return `${displayValue}%`;
                      }
                      if (childCalculations && childCalculations.length > 0) {
                        const spotCalculation = childCalculations[0];
                        const spotWeight = spotCalculation ? parseFloat(spotCalculation.currentWeight).toFixed(2) : '0.00';
                        const perpCalculation = childCalculations[1];
                        const perpWeight = perpCalculation ? parseFloat(perpCalculation.currentWeight).toFixed(2) : '0.00';
                        if (normalizedSymbol.includes(':')) {
                          return `${perpWeight}%`;
                        }
                        return `${spotWeight}%`;
                      }
                      return '0.00%';
                    }
                    case 'diff': {
                      if (isParent) {
                        const displayValue = Math.abs(parentCalculations.diff).toFixed(2);
                        return (
                          <span style={parentDiffStyle}>
                            {displayValue}%
                          </span>
                        );
                      }
                      const displayValue = Math.abs(diff).toFixed(2);
                      return (
                        <span style={diffStyle}>
                          {displayValue}%
                        </span>
                      );
                    }
                    case 'currentQuantity': {
                      if (isParent) {
                        const displayValue = Math.abs(parentCalculations.currentQuantity).toFixed(2);
                        return `${displayValue} ${normalizedSymbol}`;
                      }
                      if (childCalculations && childCalculations.length > 0) {
                        const spotCalculation = childCalculations[0];
                        const spotCurrentQuantity = spotCalculation ? parseFloat(spotCalculation.currentQuantity).toFixed(2) : '0.00';
                        const perpCalculation = childCalculations[1];
                        const perpCurrentQuantity = perpCalculation ? parseFloat(perpCalculation.currentQuantity).toFixed(2) : '0.00';
                        if (normalizedSymbol.includes(':')) {
                          return `${perpCurrentQuantity} ${normalizedSymbol}`;
                        }
                        return `${spotCurrentQuantity} ${normalizedSymbol}`;
                      }
                      return '0.00';
                    }

                    case 'currentNotional': {
                      if (isParent) {
                        const displayValue = Math.abs(parentCalculations.currentNotional).toFixed(2);
                        return `${displayValue} ${counterAsset}`;
                      }
                      if (!isParent && isPerp) {
                        const displayValue = Math.abs(assetOrBaseSymbol.currentNotional).toFixed(2);
                        const positionType = assetOrBaseSymbol.currentNotional < 0 ? 'Net Short' : 'Net Long';
                        const positionStyle = {
                          color: assetOrBaseSymbol.currentNotional < 0 ? 'red' : 'green',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        };

                        return (
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span>{displayValue} {counterAsset}</span>
                            <span style={positionStyle}>{positionType}</span>
                          </div>
                        );
                      }
                      return `${assetOrBaseSymbol.currentNotional.toFixed(2)} ${counterAsset}`;
                    }
                    case 'targetNotional': {
                      if (isParent) {
                        return `${parentCalculations.targetNotional.toFixed(2)} ${counterAsset}`;
                      }
                      if (childCalculations && childCalculations.length > 0) {
                        const spotCalculation = childCalculations[0];
                        const spotTargetNotional = spotCalculation ? parseFloat(spotCalculation.targetNotional).toFixed(2) : '0.00';
                        const perpCalculation = childCalculations[1];
                        const perpTargetNotional = perpCalculation ? parseFloat(perpCalculation.targetNotional).toFixed(2) : '0.00';
                        if (normalizedSymbol.includes(':')) {
                          return `${perpTargetNotional} ${counterAsset}`;
                        }
                        return `${spotTargetNotional} ${counterAsset}`;
                      }
                      return '0.00';
                    }
                    case 'targetQuantity': {
                      if (isParent) {
                        const displayValue = Math.abs(parentCalculations.targetQuantity).toFixed(2);
                        return `${displayValue} ${normalizedSymbol}`;
                      }
                      if (childCalculations && childCalculations.length > 0) {
                        const spotCalculation = childCalculations[0];
                        const spotTargetQuantity = spotCalculation ? parseFloat(spotCalculation.targetQuantity).toFixed(2) : '0.00';
                        const perpCalculation = childCalculations[1];
                        const perpTargetQuantity = perpCalculation ? parseFloat(perpCalculation.targetQuantity).toFixed(2) : '0.00';
                        if (normalizedSymbol.includes(':')) {
                          return `${perpTargetQuantity} ${normalizedSymbol}`;
                        }
                        return `${spotTargetQuantity} ${normalizedSymbol}`;
                      }
                      return '0.00';
                    }

                    case 'proposedTradeNotional': {
                      if (isParent) {
                        const displayValue = (parentCalculations.proposedTradeNotional).toFixed(2);
                        return `${displayValue} ${counterAsset}`;
                      }
                      if (childCalculations && childCalculations.length > 0) {
                        const spotCalculation = childCalculations[0];
                        const spotProposedTradeNotional = spotCalculation ? parseFloat(spotCalculation.proposedTradeNotional).toFixed(2) : '0.00';
                        const perpCalculation = childCalculations[1];
                        const perpProposedTradeNotional = perpCalculation ? parseFloat(perpCalculation.proposedTradeNotional).toFixed(2) : '0.00';
                        if (normalizedSymbol.includes(':')) {
                          return `${perpProposedTradeNotional} ${counterAsset}`;
                        }
                        return `${spotProposedTradeNotional} ${counterAsset}`;
                      }
                      return '0.00';
                    }

                    case 'proposedTradeQuantity': {
                      if (isParent) {
                        const displayValue = (parentCalculations.proposedTradeQuantity).toFixed(2);
                        return `${displayValue} ${normalizedSymbol}`;
                      }
                      if (childCalculations && childCalculations.length > 0) {
                        const spotCalculation = childCalculations[0];
                        const spotProposedTradeQuantity = spotCalculation ? parseFloat(spotCalculation.proposedTradeQuantity).toFixed(2) : '0.00';
                        const perpCalculation = childCalculations[1];
                        const perpProposedTradeQuantity = perpCalculation ? parseFloat(perpCalculation.proposedTradeQuantity).toFixed(2) : '0.00';
                        if (normalizedSymbol.includes(':')) {
                          return `${perpProposedTradeQuantity} ${normalizedSymbol}`;
                        }
                        return `${spotProposedTradeQuantity} ${normalizedSymbol}`;
                      }
                      return '0.00';
                    }
                    default:
                      return assetOrBaseSymbol[column.id] || 0;
                  }
                };
                const handleRowClick = (parentSymbol) => {
                  setExpandedRows((prevState) => ({
                    ...prevState,
                    [parentSymbol]: !prevState[parentSymbol],
                  }));
                };

                const renderTableRow = (type, assetOrBaseSymbol, calculations, diffStyle) => {
                  const isParent = type === 'parent';

                  return (
                    <TableRow
                      key={isParent ? assetOrBaseSymbol : assetOrBaseSymbol.symbol}
                      style={{
                        cursor: isParent ? 'pointer' : 'default',
                        backgroundColor: isParent ? '#2c2c2c' : undefined,
                      }}
                      onClick={isParent ? () => handleRowClick(assetOrBaseSymbol) : undefined}
                    >
                      {RebalanceAssetsColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          sx={{ color: '#fff', border: '1px solid #333', textAlign: column.align }}
                        >
                          {renderTableCellContent(
                            column,
                            assetOrBaseSymbol,
                            calculations.diff,
                            diffStyle,
                            isParent
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                };
                return (
                  <React.Fragment key={baseSymbol}>
                    {renderTableRow('parent', baseSymbol, parentCalculations, parentDiffStyle)}
                    {expandedRows[baseSymbol] &&
                      childSymbols.map((symbol) => {
                        const normalizedSymbol = symbol.includes(':')
                          ? symbol.replace(/-USDT|-USDC|-USD$/, '')
                          : symbol.replace(/-USDT|-USDC|-USD$/, '');

                        const asset =
                          [...filteredCombinedAssets, ...filteredInprogressAssets].find(
                            (a) => a.symbol === normalizedSymbol || a.symbol === symbol
                          );

                        if (!asset) {
                          return null;
                        }
                        const {
                          currentPrice,
                          currentWeight,
                          targetNotional,
                          currentNotional,
                          proposedTradeNotional,
                          proposedTradeQuantity,
                          diff,
                        } = handleRowCalculations(asset, currentPrices, BalanceNotional);

                        const childDiffStyle = {};
                        if (diff > 0) {
                          childDiffStyle.color = 'green';
                        } else if (diff < 0) {
                          childDiffStyle.color = 'red';
                        }

                        const childCalculations = {
                          currentPrice,
                          currentWeight: parseFloat(currentWeight),
                          targetNotional,
                          currentNotional,
                          proposedTradeNotional,
                          proposedTradeQuantity,
                          diff,
                          targetQuantity: parseFloat(Math.abs(targetNotional / currentPrice).toFixed(2)),
                          currentQuantity: parseFloat(Math.abs(currentNotional / currentPrice).toFixed(2)),
                        };
                        return renderTableRow('child', asset, childCalculations, childDiffStyle);
                      })}
                  </React.Fragment>
                );
              }
            )}
            {!inProgress &&
              Object.entries(groupedUnderlyingOurAssets).map(([baseSymbol, childSymbols]) => {
                const childCalculations = childSymbols.map((symbol) => {
                  const asset = SymbolOurAssets.find((a) => a.symbol === symbol);
                  if (!asset) {
                    return {
                      currentPrice: 0,
                      currentWeight: 0,
                      targetNotional: 0,
                      currentNotional: 0,
                      proposedTradeNotional: 0,
                      proposedTradeQuantity: 0,
                      diff: 0,
                    };
                  }

                  const {
                    currentPrice,
                    currentWeight,
                    targetNotional,
                    currentNotional,
                    proposedTradeNotional,
                    proposedTradeQuantity,
                    diff,
                  } = handleRowCalculations(asset, currentPrices, BalanceNotional);

                  const targetQuantity = Math.abs(targetNotional / currentPrice).toFixed(2);
                  const currentQuantity = Math.abs(currentNotional / currentPrice).toFixed(2);
                  return {
                    currentPrice,
                    currentWeight,
                    targetNotional,
                    currentNotional,
                    targetQuantity,
                    currentQuantity,
                    proposedTradeNotional,
                    proposedTradeQuantity,
                    diff,
                  };
                });

                const parentCalculations = childCalculations.reduce(
                  (totals, child) => ({
                    currentPrice: totals.currentPrice + (Number(child.currentPrice) || 0),
                    currentWeight: totals.currentWeight + (Number(child.currentWeight) || 0),
                    targetNotional: totals.targetNotional + (Number(child.targetNotional) || 0),
                    currentNotional: totals.currentNotional + (Number(child.currentNotional) || 0),
                    targetQuantity: totals.targetQuantity + (Number(child.targetQuantity) || 0),
                    currentQuantity: totals.currentQuantity + (Number(child.currentQuantity) || 0),
                    proposedTradeNotional: totals.proposedTradeNotional + (Number(child.proposedTradeNotional) || 0),
                    proposedTradeQuantity: totals.proposedTradeQuantity + (Number(child.proposedTradeQuantity) || 0),
                    diff: 0,
                  }),
                  {
                    currentPrice: 0,
                    currentWeight: 0,
                    targetNotional: 0,
                    currentNotional: 0,
                    targetQuantity: 0,
                    currentQuantity: 0,
                    proposedTradeNotional: 0,
                    proposedTradeQuantity: 0,
                    diff: 0,
                  }
                );

                let parentDiff;
                const targetWeight = parseFloat(parentCalculations.targetWeight) || 0;
                const targetNotional = parseFloat(parentCalculations.targetNotional) || 0;
                const currentNotional = parseFloat(parentCalculations.currentNotional) || 1;
                if (!currentNotional || Number.isNaN(currentNotional)) {
                  parentDiff = targetWeight.toFixed(2);
                } else {
                  parentDiff = ((targetNotional / currentNotional - 1) * 100).toFixed(2);
                }
                parentCalculations.diff = parentDiff === '-0.00' ? '0.00' : parentDiff;

                const parentDiffStyle = {};
                if (parentCalculations.diff > 0) {
                  parentDiffStyle.color = 'green';
                } else if (parentCalculations.diff < 0) {
                  parentDiffStyle.color = 'red';
                }

                const getTargetWeightInputWithButtons = (asset) => {
                  const symbolOrPair = asset.symbol ? trimSymbol(asset.symbol) : asset.pair;
                  const positionType =
                    positionTypes[symbolOrPair] || (targetWeights[symbolOrPair] < 0 ? "short" : "long");
                  const isPerp = formattedPerpAssetPairs.some(pair => pair.symbol === symbolOrPair);

                  const handlePositionTypeChange = (type) => {
                    setPositionTypes((prev) => ({
                      ...prev,
                      [symbolOrPair]: type,
                    }));
                    const currentWeight = parseFloat(targetWeights[symbolOrPair] || 0);
                    const adjustedWeight = type === "long" ? Math.abs(currentWeight) : -Math.abs(currentWeight);
                    setTargetWeights((prev) => ({
                      ...prev,
                      [symbolOrPair]: adjustedWeight,
                    }));
                  };

                  return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        autoComplete="off"
                        disabled={inProgress || isScheduled}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          min: 0,
                          max: 100,
                          style: {
                            backgroundColor: "#2c2c2c",
                            color: "#fff",
                            padding: "5px",
                          },
                        }}
                        type="text"
                        value={targetWeights[symbolOrPair] === undefined || targetWeights[symbolOrPair] === ''
                          ? ''
                          : Math.abs(targetWeights[symbolOrPair])}
                        onChange={(e) => handleInputChange(symbolOrPair, e)}
                      />
                      {isPerp && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "15px",
                            marginRight: "-10px",
                          }}
                        >
                          <Tooltip title="Set position to Long">
                            <span>
                              <Button
                                color="success"
                                disabled={inProgress || isScheduled}
                                size="small"
                                sx={{
                                  minWidth: "24px",
                                  height: "24px",
                                  padding: "2px",
                                  fontSize: "12px",
                                }}
                                variant={positionType === "long" ? "contained" : "outlined"}
                                onClick={() => handlePositionTypeChange("long")}
                              >
                                <TrendingUpIcon fontSize="inherit" />
                              </Button>
                            </span>
                          </Tooltip>
                          <Tooltip title="Set position to Short">
                            <span>
                              <Button
                                color="error"
                                disabled={inProgress || isScheduled}
                                size="small"
                                sx={{
                                  marginTop: "3px",
                                  minWidth: "24px",
                                  height: "24px",
                                  padding: "2px",
                                  fontSize: "12px",
                                }}
                                variant={positionType === "short" ? "contained" : "outlined"}
                                onClick={() => handlePositionTypeChange("short")}
                              >
                                <TrendingDownIcon fontSize="inherit" />
                              </Button>
                            </span>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  );
                };
                const renderTableCellContent = (column, assetOrBaseSymbol, diff, diffStyle, isParent = false, childCalculation = null) => {
                  const normalizedSymbol = isParent
                    ? assetOrBaseSymbol
                    : assetOrBaseSymbol.symbol.replace(/-USDT|-USDC|-USD$/, '');

                  const isPerp = !isParent && formattedPerpAssetPairs.some(pair => pair.symbol === normalizedSymbol);

                    switch (column.id) {
                      case 'underlying': {
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {isParent ? (
                              <Typography>{`${normalizedSymbol}`}</Typography>
                            ) : (
                                <Typography>{trimSymbol(assetOrBaseSymbol.symbol)}</Typography>
                            )}
                          </Box>
                        );
                      }
                      case 'assetType': {
                        return isParent ? (
                          <span />
                        ) : (
                          <span>
                            {normalizedSymbol.includes(':') ? 'perp' : 'spot'}
                          </span>
                        );
                      }
                      case 'targetWeight':
                        return isParent ? (
                          <>
                            {childSymbols
                              .map((childSymbol) => {
                                const normalizedChildSymbol = childSymbol.replace(/-USDT|-USDC|-USD$/, '');
                                if (targetWeights[normalizedChildSymbol] && typeof targetWeights[normalizedChildSymbol] === 'number') {
                                  return targetWeights[normalizedChildSymbol];
                                }
                                return 0;
                              })
                              .reduce((sum, weight) => sum + weight, 0)
                              .toFixed(2)}
                          </>
                        ) : (
                          getTargetWeightInputWithButtons(assetOrBaseSymbol)
                        );
                      case 'currentWeight': {
                        if (isParent) {
                          const displayValue = Math.abs(parentCalculations.currentWeight).toFixed(2);
                          return `${displayValue}%`;
                        }
                        if (childCalculations && childCalculations.length > 0) {
                          const spotCalculation = childCalculations[0];
                          const spotWeight = spotCalculation ? parseFloat(spotCalculation.currentWeight).toFixed(2) : '0.00';
                          const perpCalculation = childCalculations[1];
                          const perpWeight = perpCalculation ? parseFloat(perpCalculation.currentWeight).toFixed(2) : '0.00';
                          if (normalizedSymbol.includes(':')) {
                            return `${perpWeight}%`;
                          }
                          return `${spotWeight}%`;
                        }
                        return '0.00%';
                      }
                      case 'diff': {
                        if (isParent) {
                          const targetWeight = childSymbols
                            .map((childSymbol) => {
                              const normalizedChildSymbol = childSymbol.replace(/-USDT|-USDC|-USD$/, '');
                              if (targetWeights[normalizedChildSymbol] && typeof targetWeights[normalizedChildSymbol] === 'number') {
                                return targetWeights[normalizedChildSymbol];
                              }
                              return 0;
                            })
                            .reduce((sum, weight) => sum + weight, 0)
                            .toFixed(2);
                          const targetNotional = parentCalculations.targetNotional || 0;
                          const currentNotional = parentCalculations.currentNotional || 0;
                          const parentDiff =
                            !currentNotional || Number.isNaN(currentNotional)
                              ? targetWeight
                              : ((targetNotional / currentNotional - 1) * 100).toFixed(2);
                          const displayValue = Math.abs(parseFloat(parentDiff)).toFixed(2);
                          return (
                            <span style={parentDiffStyle}>
                              {displayValue}%
                            </span>
                          );
                        }
                        const displayValue = Math.abs(diff).toFixed(2);
                        return (
                          <span style={diffStyle}>
                            {displayValue}%
                          </span>
                        );
                      }
                      case 'currentQuantity': {
                        if (isParent) {
                          const displayValue = Math.abs(parentCalculations.currentQuantity).toFixed(2);
                          return `${displayValue} ${normalizedSymbol}`;
                        }
                        if (childCalculations && childCalculations.length > 0) {
                          const spotCalculation = childCalculations[0];
                          const spotCurrentQuantity = spotCalculation ? parseFloat(spotCalculation.currentQuantity).toFixed(2) : '0.00';
                          const perpCalculation = childCalculations[1];
                          const perpCurrentQuantity = perpCalculation ? parseFloat(perpCalculation.currentQuantity).toFixed(2) : '0.00';
                          if (normalizedSymbol.includes(':')) {
                            return `${perpCurrentQuantity} ${normalizedSymbol}`;
                          }
                          return `${spotCurrentQuantity} ${normalizedSymbol}`;
                        }
                        return '0.00';
                      }

                      case 'currentNotional': {
                        if (isParent) {
                          const displayValue = Math.abs(parentCalculations.currentNotional).toFixed(2);
                          return `${displayValue} ${counterAsset}`;
                        }
                        if (!isParent && isPerp) {
                          const displayValue = Math.abs(assetOrBaseSymbol.currentNotional).toFixed(2);
                          const positionType = assetOrBaseSymbol.currentNotional < 0 ? 'Net Short' : 'Net Long';
                          const positionStyle = {
                            color: assetOrBaseSymbol.currentNotional < 0 ? 'red' : 'green',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          };

                          return (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <span>{displayValue} {counterAsset}</span>
                              <span style={positionStyle}>{positionType}</span>
                            </div>
                          );
                        }
                        return `${assetOrBaseSymbol.currentNotional.toFixed(2)} ${counterAsset}`;
                      }
                      case 'targetNotional': {
                        if (isParent) {
                          return `${parentCalculations.targetNotional.toFixed(2)} ${counterAsset}`;
                        }
                        if (childCalculations && childCalculations.length > 0) {
                          const spotCalculation = childCalculations[0];
                          const spotTargetNotional = spotCalculation ? parseFloat(spotCalculation.targetNotional).toFixed(2) : '0.00';
                          const perpCalculation = childCalculations[1];
                          const perpTargetNotional = perpCalculation ? parseFloat(perpCalculation.targetNotional).toFixed(2) : '0.00';
                          if (normalizedSymbol.includes(':')) {
                            return `${perpTargetNotional} ${counterAsset}`;
                          }
                          return `${spotTargetNotional} ${counterAsset}`;
                        }
                        return '0.00';
                      }
                      case 'targetQuantity': {
                        if (isParent) {
                          const displayValue = Math.abs(parentCalculations.targetQuantity).toFixed(2);
                          return `${displayValue} ${normalizedSymbol}`;
                        }
                        if (childCalculations && childCalculations.length > 0) {
                          const spotCalculation = childCalculations[0];
                          const spotTargetQuantity = spotCalculation ? parseFloat(spotCalculation.targetQuantity).toFixed(2) : '0.00';
                          const perpCalculation = childCalculations[1];
                          const perpTargetQuantity = perpCalculation ? parseFloat(perpCalculation.targetQuantity).toFixed(2) : '0.00';
                          if (normalizedSymbol.includes(':')) {
                            return `${perpTargetQuantity} ${normalizedSymbol}`;
                          }
                          return `${spotTargetQuantity} ${normalizedSymbol}`;
                        }
                        return '0.00';
                      }

                      case 'proposedTradeNotional': {
                        if (isParent) {
                          const displayValue = (parentCalculations.proposedTradeNotional).toFixed(2);
                          return `${displayValue} ${counterAsset}`;
                        }
                        if (childCalculations && childCalculations.length > 0) {
                          const spotCalculation = childCalculations[0];
                          const spotProposedTradeNotional = spotCalculation ? parseFloat(spotCalculation.proposedTradeNotional).toFixed(2) : '0.00';
                          const perpCalculation = childCalculations[1];
                          const perpProposedTradeNotional = perpCalculation ? parseFloat(perpCalculation.proposedTradeNotional).toFixed(2) : '0.00';
                          if (normalizedSymbol.includes(':')) {
                            return `${perpProposedTradeNotional} ${counterAsset}`;
                          }
                          return `${spotProposedTradeNotional} ${counterAsset}`;
                        }
                        return '0.00';
                      }

                      case 'proposedTradeQuantity': {
                        if (isParent) {
                          const displayValue = (parentCalculations.proposedTradeQuantity).toFixed(2);
                          return `${displayValue} ${normalizedSymbol}`;
                        }
                        if (childCalculations && childCalculations.length > 0) {
                          const spotCalculation = childCalculations[0];
                          const spotProposedTradeQuantity = spotCalculation ? parseFloat(spotCalculation.proposedTradeQuantity).toFixed(2) : '0.00';
                          const perpCalculation = childCalculations[1];
                          const perpProposedTradeQuantity = perpCalculation ? parseFloat(perpCalculation.proposedTradeQuantity).toFixed(2) : '0.00';
                          if (normalizedSymbol.includes(':')) {
                            return `${perpProposedTradeQuantity} ${normalizedSymbol}`;
                          }
                          return `${spotProposedTradeQuantity} ${normalizedSymbol}`;
                        }
                        return '0.00';
                      }
                      default:
                        return assetOrBaseSymbol[column.id] || 0;
                    }
                  };
                  const handleRowClick = (parentSymbol) => {
                    setExpandedRows((prevState) => {
                      const newState = {
                        ...prevState,
                        [parentSymbol]: !prevState[parentSymbol],
                      };
                      return newState;
                    });
                  };

                  const renderTableRow = (type, assetOrBaseSymbol, calculations, diffStyle) => {
                    const isParent = type === 'parent';

                    return (
                      <TableRow
                        key={isParent ? assetOrBaseSymbol : assetOrBaseSymbol.symbol}
                        style={{
                          cursor: isParent ? 'pointer' : 'default',
                          backgroundColor: isParent ? '#2c2c2c' : undefined,
                        }}
                        onClick={isParent ? () => handleRowClick(assetOrBaseSymbol) : undefined}
                      >
                        {RebalanceAssetsColumns.map((column) => (
                          <TableCell
                            key={column.id}
                            sx={{ color: '#fff', border: '1px solid #333', textAlign: column.align }}
                          >
                            {renderTableCellContent(
                              column,
                              assetOrBaseSymbol,
                              calculations.diff,
                              diffStyle,
                              isParent
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  };

                  return (
                    <React.Fragment key={baseSymbol}>
                      {renderTableRow('parent', baseSymbol, parentCalculations, parentDiffStyle)}
                      {expandedRows[baseSymbol] &&
                        childSymbols.map((symbol) => {
                          const asset = (SymbolOurAssets).find(
                            (a) => a.symbol === symbol
                          );

                          if (!asset) {
                            return null;
                          }

                          const {
                            currentPrice,
                            currentWeight,
                            targetNotional,
                            currentNotional,
                            proposedTradeNotional,
                            proposedTradeQuantity,
                            diff,
                          } = handleRowCalculations(asset, currentPrices, BalanceNotional);

                          const childDiffStyle = {};
                          if (diff > 0) {
                            childDiffStyle.color = 'green';
                          } else if (diff < 0) {
                            childDiffStyle.color = 'red';
                          }

                          const childCalculations = {
                            currentPrice,
                            currentWeight: parseFloat(currentWeight),
                            targetNotional,
                            currentNotional,
                            proposedTradeNotional,
                            proposedTradeQuantity,
                            diff,
                            targetQuantity: parseFloat(Math.abs(targetNotional / currentPrice).toFixed(2)),
                            currentQuantity: parseFloat(Math.abs(currentNotional / currentPrice).toFixed(2)),
                          };
                          return (
                            <TableRow key={symbol}>
                              {RebalanceAssetsColumns.map((column) => (
                                <TableCell
                                  key={column.id}
                                  sx={{ color: '#fff', border: '1px solid #333', textAlign: column.align }}
                                >
                                  {renderTableCellContent(
                                    column,
                                    asset,
                                    childCalculations.diff,
                                    childDiffStyle
                                  )}
                                </TableCell>
                              ))}
                              <TableCell
                                sx={{
                                  textAlign: 'center',
                                  padding: '4px',
                                }}
                              >
                                <IconButton color="error" size="small" onClick={() => handleRemoveRow(symbol)}>
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  );
                })
              }
              {showCashRow && (
                <TableRow>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'left' }}>
                    Cash
                  </TableCell>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                    {cashTargetWeight}%
                  </TableCell>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                    {cashTargetNotional.toFixed(2)} {counterAsset}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                    {cashCurrntNotional.toFixed(2)} {counterAsset}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                    {-formattedTotalProposedTradeNotional} {counterAsset}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                  <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                </TableRow>
              )}
              <TableRow>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'left' }}>
                    Total
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  {showCashRow
                    ? `${(parseFloat(totalTargetWeight) + parseFloat(cashTargetWeight)).toFixed(2)}%`
                    : `${parseFloat(totalTargetWeight).toFixed(2)}%`}
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  {totalCurrentWeight}%
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  {totalTargetNotional} {counterAsset}
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  {totalCurrentNotional.toFixed(2)} {counterAsset}
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  {formattedTotalProposedTradeNotional} {counterAsset}
                </TableCell>
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }} />
                <TableCell sx={{ color: '#fff', border: '1px solid #333', textAlign: 'right' }}>
                  <span style={{ color: getFormattedTotalDiffColor(formattedTotalDiff) }}>
                    {formattedTotalDiff}%
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ width: '30%', display: 'flex', marginLeft: '50px' }}>
          <Box sx={{ width: '80%', height: '300px' }}>
            <Doughnut data={doughnutDatatarget} options={{ maintainAspectRatio: false }} />
            <Typography align="center">Target Weight</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '20px', marginTop: '20px' }}>
        <FormControl sx={{ width: '150px' }}>
          <InputLabel id="counter-asset-label">Counter Asset</InputLabel>
          <Select
            label="Counter Asset"
            labelId="counter-asset-label"
            value={counterAsset}
            onChange={!inProgress || !isScheduled ? (e) => setCounterAsset(e.target.value) : null}
          >
            <MenuItem value="USDT">USDT</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="USDC">USDC</MenuItem>
          </Select>
          <Typography variant="body1">
            Balance of {counterAsset}: {msAndKs(truncate(getAssetQuantityForCounterAsset()), 2)} {counterAsset}
          </Typography>
          <Typography variant="body1">
            Total Balance: {totalCurrentNotionalAllFormatted} {counterAsset}
          </Typography>
        </FormControl>
        {content}
      </Box>
      <Box sx={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
        <Button
          disabled={isRebalanceButtonDisabled}
          sx={{ backgroundColor: '#f39c12', width: '200px' }}
          variant="contained"
          onClick={handleRebalance}
        >
          {getRebalanceButtonText()}
        </Button>
        <Button
          disabled={isStopButtonDisabled}
          sx={{ backgroundColor: '#e74c3c', width: '200px' }}
          variant="contained"
          onClick={handleStopRebalanceConfirmation}
        >
          {getStopButtonText()}
        </Button>
      </Box>
      {showConfirmation && (
        <Modal
          open={showConfirmation}
          onClose={() => {
            setShowConfirmation(false);
            setSubmitLoading(false);
          }}
        >
          <Box
            sx={{
              backgroundColor: '#2c2c2c',
              color: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '500px',
              maxHeight: '80vh',
              overflowY: 'auto',
              margin: 'auto',
              marginTop: '10%',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              position: 'relative'
            }}
          >
            <IconButton
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color: '#fff'
              }}
              onClick={() => {
                setShowConfirmation(false);
                setSubmitLoading(false);
              }}
            >
              ✖️
            </IconButton>
            <Typography sx={{ marginBottom: '20px', textAlign: 'center' }} variant="h6">
              Confirm Your Trades
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: '#fff' }}>Pair</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Side</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {childOrders.map((order) => {
                  const baseSymbol = order.pair ? order.pair.split('-')[0] : order.symbol;
                  const fullSymbol = `${baseSymbol}-USDT`;
                  const currentPrice = currentPrices[fullSymbol] || currentPrices[baseSymbol] || 0;
                  const uniqueKey = `${order.pair}-${order.side}`;

                  const isPerp = formattedPerpAssetPairs.some(pair => pair.symbol === baseSymbol);
                  const targetWeight = targetWeights[fullSymbol] || targetWeights[baseSymbol] || 0;
                  const hasNonZeroTargetWeight = targetWeight !== 0;

                  let sideDescription;
                  if (!hasNonZeroTargetWeight && isPerp) {
                    sideDescription = order.side === 'buy' ? 'Close Short' : 'Close Long';
                  } else if (isPerp) {
                    sideDescription = order.side === 'buy' ? 'Buy (Long)' : 'Sell (Short)';
                  } else {
                    sideDescription = order.side === 'buy' ? 'Buy' : 'Sell';
                  }

                  return (
                    <TableRow key={uniqueKey}>
                      <TableCell sx={{ color: '#fff' }}>{order.pair}</TableCell>
                      <TableCell
                        sx={{
                          color: order.side === 'buy' ? 'green' : 'red',
                          fontWeight: 'bold',
                        }}
                      >
                        {sideDescription}
                      </TableCell>
                      <TableCell sx={{ color: '#fff' }}>
                        {(order.base_asset_qty * currentPrice).toFixed(2)} {counterAsset}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                sx={{ backgroundColor: '#f39c12', color: '#fff' }}
                variant="contained"
                onClick={confirmRebalance}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      {showStopConfirmation && (
        <Modal
          open={showStopConfirmation}
          onClose={() => setShowStopConfirmation(false)}
        >
          <Box
            sx={{
              backgroundColor: '#2c2c2c',
              color: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '400px',
              margin: 'auto',
              marginTop: '20%',
              textAlign: 'center',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
            }}
          >
            <Typography sx={{ marginBottom: '20px' }} variant="h6">
              Confirm Stop Rebalance
            </Typography>
            <Typography sx={{ marginBottom: '20px' }}>
              Are you sure you want to stop the rebalance process? This action cannot be undone.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button
                sx={{ backgroundColor: '#e74c3c', color: '#fff' }}
                variant="contained"
                onClick={confirmStopRebalance}
              >
                Yes, Stop
              </Button>
              <Button
                sx={{ backgroundColor: '#2ecc71', color: '#fff' }}
                variant="contained"
                onClick={() => setShowStopConfirmation(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
      <Modal
        aria-describedby="pair-selector-modal-description"
        aria-labelledby="pair-selector-modal"
        open={showPairSelectorModal}
        onClose={() => {
          setShowPairSelectorModal(false);
          setNewRowIndex(null);
        }}
      >
        <Box
          sx={{
            backgroundColor: '#333',
            padding: '20px',
            borderRadius: '5px',
            margin: 'auto',
            marginTop: '10%',
            width: '400px',
          }}
        >
          <Typography id="pair-selector-modal" sx={{ color: '#fff' }} variant="h6">
            Select a Pair
          </Typography>
          <PairSelector
            multiOrder
            accounts={transformedAccountData}
            balances={transformedBalanceData}
            favourites={favouritePairs}
            pairs={remainingTokenPairs}
            selectedAccounts={selectedAccountData.map((acc) => acc.account_name)}
            selectedPairName=""
            setFavourites={setFavouritePairs}
            setSelectedPair={(pair) => handlePairChange(newRowIndex, pair.label)} // Pass newRowIndex
            showAlert={showAlert}
          />
        </Box>
      </Modal>
      <Modal
        aria-describedby="rebalance-settings-modal-description"
        aria-labelledby="rebalance-settings-modal-title"
        open={isSettingsOpen}
        onClose={handleCloseSettings}
      >
          <Box
            sx={{
              backgroundColor: '#2c2c2c',
              color: '#fff',
              padding: '30px',
              borderRadius: '10px',
              width: '60%',
              height: '28vh',
              overflowY: 'auto',
              margin: 'auto',
              marginTop: '10%',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
          <Typography component="h2" id="rebalance-settings-modal-title" variant="h6">
            Parent Order Settings
          </Typography>

          <Box sx={{ marginTop: '10px' }}>
            <AccountRebalanceSubmitForm {...multiOrderFormProps} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <Button color="secondary" variant="contained" onClick={handleCloseSettings}>
              Close
            </Button>
            <Button color="primary" variant="contained" onClick={handleSaveSettings}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      {message && <Typography sx={{ marginTop: '20px', color: 'white' }}>{message}</Typography>}
    </Box>
  );
}

export default AccountRebalance;
