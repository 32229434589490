import React, { useContext, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Box, Typography, Button } from '@mui/material';
import AccountDropdown from '@/shared/fields/AccountDropdown';
import { BuySellButtons } from '@/pages/dashboard/orderEntry/BuySellButtons';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import TrajectoryDropdown from '@/shared/fields/TrajectoryDropdown';
import { PassivenessSlider, AlphaTiltSlider } from '@/shared/fields/Sliders';
import { useBaseForm } from './hooks/useBaseForm';
import { AccountBalanceProvider } from './AccountBalanceContext';
import { QtyInputField } from './QtyInputField';
import OrderUrgencyPicker from './OrderUrgencyPicker';
import useAutoOrderEntryForm from './hooks/useAutoOrderEntryForm';
import { useSubmitForm } from './hooks/useSubmitForm';
import { OrderConfirmationModal } from './OrderConfirmationModal';
import BorderedStack from './AlgoOrderFieldsComponents/BorderedStack';
import DurationField from './AlgoOrderFieldsComponents/DurationField';

function AutoOrderEntryForm({ FormAtoms }) {
  const { showAlert, setHasError } = useContext(ErrorContext);
  const {
    autoOrderUrgencies,
    trajectories,
    handleCoreFields,
    quoteBaseStates,
    handleBaseQuoteFields,
    percentageSliderInfo,
  } = useBaseForm({
    showAlert,
    FormAtoms,
  });

  const {
    baseQty,
    quoteQty,
    baseQtyPlaceholder,
    quoteQtyPlaceholder,
    baseContractQty,
    basePercentage,
    quotePercentage,
    convertedQtyLoading,
    accounts,
    selectedAccounts,
    selectedPair,
    selectedSide,
    setSelectedAccounts,
    setBasePercentage,
    setQuotePercentage,
  } = quoteBaseStates;

  const {
    handleBaseQtyOnChange,
    handleQuoteQtyOnChange,
    onBasePercentageChangeCommit,
    onQuotePercentageChangeCommit,
  } = handleBaseQuoteFields;

  const { totalQuoteAsset, totalBaseAsset } = percentageSliderInfo;

  const {
    urgency,
    setUrgency,
    enableUrgency,
    isAutoOrderFormLoading,
    autoOrderConfig,
    configFields,
    autoOrderDescription,
  } = useAutoOrderEntryForm({
    FormAtoms,
    trajectories,
    accounts,
  });

  const { ConfirmationModalProps, submitCheck, isSubmitted } = useSubmitForm({
    showAlert,
    setHasError,
    FormAtoms,
  });

  const isBuySide = selectedSide === 'buy';
  const isReadyToPickQty =
    !isAutoOrderFormLoading &&
    selectedAccounts.length > 0 &&
    selectedPair &&
    Object.keys(selectedPair).length > 0;

  const { handleSelectedAccountsChange, handleSelectedSide } = handleCoreFields;

  const {
    passiveness,
    setPassiveness,
    alphaTilt,
    setAlphaTilt,
    trajectory,
    setTrajectory,
    duration,
    setDuration,
  } = configFields;

  // If there is only one account, select it by default
  useEffect(() => {
    const account_names = Object.keys(accounts);
    if (account_names.length === 1) {
      setSelectedAccounts([account_names[0]]);
    }
  }, []);

  const onFormSubmit = (e) => {
    submitCheck(e, []);
  };

  return (
    <Box sx={{ height: '100%' }}>
      <form
        style={{ height: '100%', position: 'relative' }}
        onSubmit={onFormSubmit}
      >
        <Grid container spacing={2}>
          <Grid xs={6}>
            <AccountDropdown
              multiple
              accounts={accounts}
              extraStyling={{ height: '50.25px' }}
              handleSelectedAccountsChange={(e) =>
                handleSelectedAccountsChange(e.target.value)
              }
              handleSelectedAccountsDelete={(value) =>
                handleSelectedAccountsChange(value)
              }
              selectedAccounts={selectedAccounts}
            />
          </Grid>
          <Grid xs={6}>
            <BuySellButtons
              disabled={isAutoOrderFormLoading}
              handleSelectedSide={handleSelectedSide}
              isBuySide={isBuySide}
              selectedPair={selectedPair}
              selectedSide={selectedSide}
            />
          </Grid>
          <AccountBalanceProvider FormAtoms={FormAtoms} showAlert={showAlert}>
            <Grid xs={6}>
              <QtyInputField
                isBase
                contractQty={baseContractQty}
                convertedQtyLoading={convertedQtyLoading}
                handleQtyOnChange={handleBaseQtyOnChange}
                isBuySide={isBuySide}
                isReadyToPickQty={isReadyToPickQty}
                oppositeQtyExists={!!quoteQty}
                percentage={basePercentage}
                qty={baseQty}
                qtyPlaceholder={baseQtyPlaceholder}
                selectedPair={selectedPair}
                setPercentage={setBasePercentage}
                totalBaseAsset={totalBaseAsset}
                totalQuoteAsset={totalQuoteAsset}
                onPercentageChangeCommit={onBasePercentageChangeCommit}
              />
            </Grid>
            <Grid xs={6}>
              <QtyInputField
                convertedQtyLoading={convertedQtyLoading}
                handleQtyOnChange={handleQuoteQtyOnChange}
                isBase={false}
                isBuySide={isBuySide}
                isReadyToPickQty={isReadyToPickQty}
                oppositeQtyExists={!!baseQty}
                percentage={quotePercentage}
                qty={quoteQty}
                qtyPlaceholder={quoteQtyPlaceholder}
                selectedPair={selectedPair}
                setPercentage={setQuotePercentage}
                totalBaseAsset={totalBaseAsset}
                totalQuoteAsset={totalQuoteAsset}
                onPercentageChangeCommit={onQuotePercentageChangeCommit}
              />
            </Grid>
          </AccountBalanceProvider>

          <Grid xs={12}>
            <OrderUrgencyPicker
              disabled={!enableUrgency}
              loading={isAutoOrderFormLoading}
              setUrgency={setUrgency}
              urgencies={autoOrderUrgencies}
              urgency={urgency}
            />
          </Grid>

          {autoOrderConfig && (
            <>
              <Grid xs={12}>
                <Typography variant='subtitle1'>Description</Typography>
                <Typography variant='body2'>{autoOrderDescription}</Typography>
              </Grid>
              <Grid xs={12}>
                <TrajectoryDropdown
                  setTrajectory={setTrajectory}
                  trajectories={trajectories}
                  trajectory={trajectory}
                />
              </Grid>
              <Grid xs={12}>
                <DurationField
                  FormAtoms={FormAtoms}
                  selectedDuration={duration}
                  setSelectedDuration={setDuration}
                />
              </Grid>
              <Grid xs={12}>
                <BorderedStack spacing={1} title='Strategy Parameters'>
                  <PassivenessSlider
                    passiveness={passiveness}
                    setPassiveness={setPassiveness}
                  />
                  <AlphaTiltSlider
                    alphaTilt={alphaTilt}
                    setAlphaTilt={setAlphaTilt}
                  />
                </BorderedStack>
              </Grid>
              <Grid xs={12}>
                <Button
                  fullWidth
                  color={isBuySide ? 'success' : 'error'}
                  disabled={!autoOrderConfig}
                  size='large'
                  type='submit'
                  variant='contained'
                >
                  Submit {isBuySide ? 'Buy' : 'Sell'} Order
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </form>
      <OrderConfirmationModal
        {...ConfirmationModalProps(isBuySide)}
        FormAtoms={FormAtoms}
      />
    </Box>
  );
}

export default AutoOrderEntryForm;
