/**
 * This module provides functionality for fetching and formatting blockchain events related to attestations.
 * It includes utilities for fetching both trade data and risk attestation events from the blockchain,
 * with support for pagination, retries, and error handling.
 *
 * The module exports:
 * - fetchEvents: Core function for fetching events with retry logic and error handling
 * - formatTradeEvent: Formats raw trade attestation events into a standardized structure
 * - formatRiskEvent: Formats raw risk attestation events into a standardized structure
 * - fetchAttestedToDataEvents: Fetches trade attestation events
 * - fetchAttestedToRiskEvents: Fetches risk attestation events
 */

// TODO: Migrate to: import { getContract, getAllEvents } from '@treadfi/contracts';
import { ethers } from 'ethers';
import { abis } from './ProofAbis';

/**
 * A semaphore implementation for limiting concurrent promises
 * @class
 * @classdesc Provides concurrency control for executing multiple promises
 * by limiting the number of promises that can execute simultaneously.
 * Useful for rate limiting API calls or managing resource usage.
 */
class PromiseSemaphore {
  /**
   * Creates a new PromiseSemaphore instance
   * @param {number} maxConcurrent - Maximum number of concurrent promises allowed (default: 5)
   */
  constructor(maxConcurrent = 9) {
    this.maxConcurrent = maxConcurrent;
    this.current = 0;
    this.queue = [];
  }

  async acquire() {
    if (this.current < this.maxConcurrent) {
      this.current += 1;
      return undefined;
    }
    return new Promise((resolve) => {
      this.queue.push(resolve);
    });
  }

  release() {
    if (this.queue.length > 0) {
      const next = this.queue.shift();
      next();
    } else {
      this.current -= 1;
    }
  }

  async execute(fn) {
    await this.acquire();
    try {
      return await fn();
    } finally {
      this.release();
    }
  }

  async executeAll(tasks) {
    return Promise.all(tasks.map((task) => this.execute(task)));
  }
}

/**
 * Runs multiple async tasks with concurrency control
 * @param {Array<Function>} tasks - Functions that return promises
 * @param {number} [poolSize=9] - Max concurrent tasks
 * @returns {Promise<Array>} Results from all tasks
 */
function promisePool(tasks, poolSize = 9) {
  const semaphore = new PromiseSemaphore(poolSize);
  return semaphore.executeAll(tasks);
}

/**
 * Fetches events from the blockchain within a specified block range
 * @param {Object} config - Configuration object for the blockchain connection
 * @param {string} config.rpcUrl - RPC endpoint URL
 * @param {string} config.attestationAddress - Contract address to query
 * @param {number} config.numberOfBlocks - Number of blocks to query in each batch
 * @param {number} config.retry - Number of retry attempts
 * @param {number} config.paginationNumber - Pagination offset
 * @param {function} setHasError - Function to set error state
 * @param {function} setErrorContent - Function to set error message content
 * @returns {Promise<Array>} Array of events
 */
async function fetchEvents(
  config,
  eventName,
  formatEvent
) {
  const { rpcUrl, attestationAddress, numberOfBlocks, paginationNumber } =
    config;

  console.debug(`[fetchEvents:${eventName}] Starting fetch with config:`, {
    rpcUrl,
    attestationAddress,
    numberOfBlocks,
    paginationNumber,
  });

  const provider = new ethers.JsonRpcProvider(rpcUrl);
  const currentBlock = await provider.getBlockNumber();
  const fromBlock =
    currentBlock - paginationNumber * numberOfBlocks - numberOfBlocks;

  const contract = new ethers.Contract(attestationAddress, abis, provider);

  // Debug contract interface and event details
  const eventFragment = contract.interface.getEvent(eventName);
  console.debug(`[fetchEvents:${eventName}] Contract and event details:`, {
    contract: {
      address: await contract.getAddress(),
      interface: {
        fragments: contract.interface.fragments.map((f) => f.name),
        hasEvent: contract.interface.hasEvent(eventName),
        eventFragment: eventFragment
          ? {
            name: eventFragment.name,
            inputs: eventFragment.inputs.map((i) => ({
              name: i.name,
              type: i.type,
              indexed: i.indexed,
            })),
          }
          : null,
      },
    },
    provider: {
      network: await provider.getNetwork().then((n) => ({
        name: n.name,
        chainId: n.chainId,
      })),
      ready: provider.ready,
    },
  });

  const eventSignature = contract.interface.getEvent(eventName).format();
  const eventTopic = ethers.id(eventSignature);

  // Calculate all batch ranges upfront
  const batchSize = 5000;
  const batches = [];
  for (
    let batch = fromBlock;
    batch < currentBlock - paginationNumber * numberOfBlocks;
    batch += batchSize
  ) {
    const endBlock = Math.min(batch + batchSize, currentBlock);
    batches.push({ startBlock: batch, endBlock });
  }

  // Process batches with concurrency limit
  const batchResults = await promisePool(
    batches.map(({ startBlock, endBlock }) => async () => {
      const [filterEvents, logEvents] = await Promise.all([
        contract.queryFilter(eventName, startBlock, endBlock),
        provider.getLogs({
          address: attestationAddress,
          topics: [eventTopic],
          fromBlock: startBlock,
          toBlock: endBlock,
        }),
      ]);

      const batchEvents = filterEvents.length
        ? filterEvents
        : logEvents
          .map((log) => {
            try {
              return contract.interface.parseLog(log);
            } catch (e) {
              console.error(`Failed to parse log:`, e, log);
              return null;
            }
          })
          .filter(Boolean);

      return batchEvents.map(formatEvent);
    }),
    5
  );

  return batchResults.flat();
}

// Event formatters
/**
 * Formats a trade attestation event into a standardized object structure
 * @param {Object} event - The raw blockchain event
 * @param {string} event.transactionHash - Hash of the transaction
 * @param {number} event.blockNumber - Block number where event occurred
 * @param {Object} event.args - Event arguments
 * @param {string} event.args.traderId - ID of the trader
 * @param {number} event.args.epoch - Epoch number
 * @param {string} event.args.attester - Address of the attester
 * @param {Object} event.args.record - Trade record data
 * @param {string} event.args.record.merkleRoot - Merkle root of the trade data
 * @param {string} event.args.record.cid - Content ID for trade data
 * @returns {Object} Formatted trade event object
 */
export const formatTradeEvent = (event) => ({
  transactionHash: event.transactionHash,
  blockNumber: event.blockNumber,
  traderId: event.args.traderId,
  epoch: event.args.epoch,
  attester: event.args.attester,
  data: {
    merkleRoot: event.args.record.merkleRoot,
    cid: event.args.record.cid,
  },
  eventName: 'Data',
  eventColor: 'success',
});

/**
 * Formats a risk attestation event into a standardized object structure
 * @param {Object} event - The raw blockchain event
 * @param {string} event.transactionHash - Hash of the transaction
 * @param {number} event.blockNumber - Block number where event occurred
 * @param {Object} event.args - Event arguments
 * @param {string} event.args.traderId - ID of the trader
 * @param {number} event.args.epoch - Epoch number
 * @param {string} event.args.attester - Address of the attester
 * @param {Array} event.args.record - Risk record data
 * @param {string} event.args.parameterId - ID of the risk parameter
 * @returns {Object} Formatted risk event object
 */
export const formatRiskEvent = (event) => ({
  transactionHash: event.transactionHash,
  blockNumber: event.blockNumber,
  traderId: event.args.traderId,
  epoch: event.args.epoch,
  attester: event.args.attester,
  data: parseInt(event.args.record[0], 10),
  parameterId: event.args.parameterId,
  eventName: 'Risk',
  eventColor: 'warning',
});

/**
 * Creates an empty event object with default values
 * @returns {Object} Empty event object with null/empty values for all fields
 */
export const createEmptyEvent = () => ({
  transactionHash: '',
  blockNumber: null,
  traderId: '',
  epoch: null,
  attester: '',
  data: {},
  eventName: 'Error',
  eventColor: 'error',
})

/**
 * Fetches attestation events for trade data
 * @param {Object} config - Configuration object for blockchain connection
 * @param {function} setHasError - Function to set error state
 * @param {function} setErrorContent - Function to set error message
 * @returns {Promise<Array>} Array of formatted trade attestation events
 */
export const fetchAttestedToDataEvents = (config) =>
  fetchEvents(config, 'AttestedToData', formatTradeEvent);

/**
 * Fetches attestation events for risk parameters
 * @param {Object} config - Configuration object for blockchain connection  
 * @param {function} setHasError - Function to set error state
 * @param {function} setErrorContent - Function to set error message
 * @returns {Promise<Array>} Array of formatted risk attestation events
 */
export const fetchAttestedToRiskEvents = (config) =>
  fetchEvents(config, 'AttestedToRisk', formatRiskEvent);