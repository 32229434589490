import moment from 'moment';

export const calculateTotalValue = (balance) => {
  let total = 0;

  if (balance.assets && balance.assets.length > 0) {
    balance.assets.forEach((a) => {
      if (a.asset_type === 'position') {
        total += a.unrealized_profit;
      } else {
        total += a.notional;
      }
    });
  }

  return total;
};

export const balanceToRow = (balance, pastSnapshots) => {
  let dayAgoDiffPercentage = null;
  let weekAgoDiffPercentage = null;
  let dayAgoDiff = null;
  let weekAgoDiff = null;

  if (!balance) {
    return {};
  }
  const currentTotalValue = balance.totalValue;

  if (balance.account_name === 'All Accounts') {
    return {
      name: balance.account_name,
      exchange: balance.exchange_name,
      userId: balance.user_id,
      username: balance.username,
      totalValue: currentTotalValue,
      accountId: balance.account_id,
      lastUpdated: `Updated ${moment(balance.timestamp_millis).fromNow()}`,
    };
  }

  if (pastSnapshots && pastSnapshots[balance.account_name]) {
    const dayAgoSnapshot = pastSnapshots[balance.account_name].day_ago;
    const weekAgoSnapshot = pastSnapshots[balance.account_name].week_ago;

    if (dayAgoSnapshot) {
      const prevTotalValue = calculateTotalValue(dayAgoSnapshot);

      dayAgoDiff = currentTotalValue - prevTotalValue;
      dayAgoDiffPercentage =
        prevTotalValue === 0 ? 0 : (100 * dayAgoDiff) / prevTotalValue;
    }

    if (weekAgoSnapshot) {
      const prevTotalValue = calculateTotalValue(weekAgoSnapshot);
      weekAgoDiff = currentTotalValue - prevTotalValue;
      weekAgoDiffPercentage =
        prevTotalValue === 0 ? 0 : (100 * weekAgoDiff) / prevTotalValue;
    }
  }

  return {
    name: balance.account_name,
    exchange: balance.exchange_name,
    userId: balance.user_id,
    username: balance.username,
    totalValue: currentTotalValue,
    dayAgoDiff,
    weekAgoDiff,
    dayAgoDiffPercentage,
    weekAgoDiffPercentage,
    accountId: balance.account_id,
    lastUpdated: `Updated ${moment(balance.timestamp_millis).fromNow()}`,
  };
};
