import React from 'react';
import {
  ToggleButton,
  Typography,
  FormControl,
  FormLabel,
  Box,
} from '@mui/material';
import LoadingToggleButtonGroup from '@/shared/components/LoadingToggleButtonGroup';

function OrderUrgencyPicker({
  disabled,
  loading,
  urgencies = [],
  urgency,
  setUrgency,
}) {
  const handleUrgencyChange = (e, newUrgency) => {
    setUrgency(newUrgency);
  };

  return (
    <FormControl sx={{ width: '100%' }}>
      <FormLabel>Urgency Level</FormLabel>
      <Box sx={{ mt: 1 }}>
        <LoadingToggleButtonGroup
          exclusive
          fullWidth
          color='primary'
          disabled={disabled}
          loading={loading}
          value={urgency}
          onChange={handleUrgencyChange}
        >
          {urgencies.map((option) => (
            <ToggleButton key={option.key} value={option.key}>
              <Typography variant='body1'>{option.name}</Typography>
            </ToggleButton>
          ))}
        </LoadingToggleButtonGroup>
      </Box>
    </FormControl>
  );
}

export default OrderUrgencyPicker;
