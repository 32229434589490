/* eslint-disable no-await-in-loop */
import Box from '@mui/material/Box';
import React, { useContext, useEffect, useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useAtom } from 'jotai';
import { SharedOrderTable } from '../../shared/orderTable/SharedOrderTable';
import { BASEURL } from '../../util';

import { ErrorContext } from '../../shared/context/ErrorProvider';
import ChipStatusFilter from '../../shared/orderTable/ChipStatusFilter';
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';
import usePolling from '../../shared/hooks/usePolling';
import { getOrderTableRows } from '../../apiServices';

function OrderTable({ FormAtoms }) {
  const [orders, setOrders] = useState([]);
  const [statusHighlight, setStatusHighlight] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeFilter, setTypeFilter] = useState([]);
  const [pairFilter, setPairFilter] = useState(false);
  const [selectedPair, setSelectedPair] = useAtom(FormAtoms.selectedPairAtom);

  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const { user, isDev } = useUserMetadata();

  useEffect(() => {
    setPairFilter(false);
  }, [selectedPair]);

  const getDashboardOrders = async (reload = false) => {
    if (!user || !user.is_authenticated) {
      return false;
    }

    // don't show loading mask for interval reload
    if (!reload) {
      setLoading(true);
    }

    const params = {
      status: statusHighlight,
      type: typeFilter,
      page_size: 10,
      market_type: ['spot', 'perp', 'future'],
      market_type_filter_exception: true,
    };
    if (pairFilter && selectedPair.id) {
      params.pair = selectedPair.id;
    }

    try {
      const data = await getOrderTableRows(params);

      setOrders(data.orders);
      if (!reload) {
        setLoading(false);
      }
      return true;
    } catch (error) {
      setHasError(true);
      setErrorContent(error.message);
      return false;
    }
  };
  useEffect(() => {
    let isMounted = true;
    let success = true;
    let initialPoll = true;

    const pollData = async () => {
      while (isMounted && success) {
        success = await getDashboardOrders(!initialPoll);
        initialPoll = false;
        setLoading(false);
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
      }
    };

    pollData();

    return () => {
      // Stop polling when the component unmounts or success changes
      isMounted = false;
    };
  }, [statusHighlight, typeFilter, pairFilter]);

  return (
    <>
      <ChipStatusFilter
        dashboardView
        isDev={isDev}
        isSuperUser={user && user.is_superuser}
        loadOrders={getDashboardOrders}
        pairFilter={pairFilter}
        selectedPair={selectedPair}
        setLoading={setLoading}
        setPairFilter={setPairFilter}
        setStatusHighlight={setStatusHighlight}
        setTypeFilter={setTypeFilter}
        statusHighlight={statusHighlight}
        typeFilter={typeFilter}
      />
      <Box style={{ height: 'calc(100% - 52px)', overflow: 'auto' }}>
        {loading ? (
          <Box
            alignItems='center'
            display='flex'
            height='100%'
            justifyContent='center'
          >
            <ScaleLoader color='#FFFFFF' />
          </Box>
        ) : (
          <SharedOrderTable
            dashboardView
            FormAtoms={FormAtoms}
            orderData={orders}
            orderRefresh={getDashboardOrders}
          />
        )}
      </Box>
    </>
  );
}

export default OrderTable;
