import { ErrorContext } from '@/shared/context/ErrorProvider';
import { useUserMetadata } from '@/shared/context/UserMetadataProvider';
import { useContext, useEffect, useState } from 'react';
import { selectBasescanConfig } from './ProofConfig';
import {
  fetchAttestedToDataEvents,
  fetchAttestedToRiskEvents,
} from './ProofFetchers';

/**
 * Custom hook to manage proof event fetching and pagination
 * @param {Object} options
 * @param {Function} options.setHasError - Error state setter
 * @param {Function} options.setErrorContent - Error content setter
 * @param {number} options.rowsPerPage - Number of rows per page
 * @returns {Object} Event data and control functions
 */
export function useProofEvents({ setHasError, setErrorContent, rowsPerPage }) {
  const [proofs, setProofs] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [paginationTracker, setPaginationTracker] = useState(0);
  const { isDev } = useUserMetadata();
  const { showAlert } = useContext(ErrorContext);

  const fetchEvents = async (paginationNumber = 0) => {
    const config = selectBasescanConfig(isDev, paginationNumber);
    setLoading(true);

    try {
      const [dataEvents, riskEvents] = await Promise.all([
        fetchAttestedToDataEvents(config),
        fetchAttestedToRiskEvents(config),
      ]);

      return [...dataEvents, ...riskEvents];
    } catch (error) {
      console.error('[fetchEvents] error', error);
      showAlert({
        severity: 'error',
        message: `Error fetching events: ${error.message}`,
      });
      return [];
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage >= proofs.length) {
      const result = await fetchEvents(paginationTracker + 1);
      setProofs((prev) => [...result, ...prev]);
      setPaginationTracker((prev) => prev + 1);
    }
    setPage(newPage);
  };

  useEffect(() => {
    fetchEvents().then((result) => {
      setProofs((prev) => [...result, ...prev]);
    });

    const interval = setInterval(() => {
      fetchEvents(paginationTracker).then((result) => {
        setProofs(result);
        setPage(0);
      });
    }, 600000);

    return () => clearInterval(interval);
  }, []);

  return {
    proofs,
    page,
    loading,
    handleChangePage,
  };
}
