import { AccountsContext } from '@/shared/context/AccountsProvider';
import { matchesTraderId } from '@/shared/cryptoUtil';
import {
  displayDefaultTableCell,
  StyledHeaderTableCellWithLine,
  StyledTableCell,
} from '@/shared/orderTable/util';
import { useTheme } from '@emotion/react';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import basescanLight from '../../../images/logos/basescan-light.png';
import { insertEllipsis } from './insertEllipsis';
import { useProofEvents } from './proofUtils/useProofEvents';
import { TraderIdAutocomplete } from './TraderIdAutocomplete';

const columns = [
  { id: 'blockNumber', label: 'Block', width: 60, align: 'left' },
  { id: 'transactionHash', label: 'Transaction ID', width: 260, align: 'left' },
  { id: 'eventName', label: 'Proof Type', width: 100, align: 'left' },
  { id: 'data', label: 'Data', width: 200, align: 'left' },
  { id: 'traderId', label: 'Trader ID', width: 60, align: 'left' },
  { id: 'attester', label: 'Attester', width: 50, align: 'left' },
  { id: 'actions', label: 'Actions', width: 30, align: 'center' },
];

/**
 * Component that displays a table of proof events from the blockchain
 * @param {Object} props - Component props
 * @param {function} props.setHasError - Function to set error state
 * @param {function} props.setErrorContent - Function to set error message content
 * @param {boolean} props.pageView - Whether component is rendered in page view mode
 * @returns {JSX.Element} Table component displaying proof events
 */
export default function ExplorerProofsTable({
  setHasError,
  setErrorContent,
  pageView,
}) {
  const [traderIdFilter, setTraderIdFilter] = useState('');
  const rowsPerPage = pageView ? 10 : 25;
  const navigate = useNavigate();
  const theme = useTheme();
  const { accounts } = useContext(AccountsContext);

  const { proofs, page, loading, handleChangePage } = useProofEvents({
    setHasError,
    setErrorContent,
    rowsPerPage,
  });

  return (
    <Card sx={{ height: '95%', display: 'flex', flexDirection: 'column' }}>
      <Stack
        alignItems='center'
        direction='row'
        spacing={2}
        style={{ paddingLeft: '20px', paddingTop: '20px', flexShrink: 0 }}
      >
        <img alt='Base Scan' src={basescanLight} style={{ height: '30px' }} />
        <Typography variant='h4'>Oracle</Typography>
      </Stack>
      <CardContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden', // Prevent content overflow
        }}
      >
        <Box sx={{ mb: 2, flexShrink: 0 }}>
          <TraderIdAutocomplete
            accounts={accounts}
            value={traderIdFilter}
            onChange={(event, newValue) => {
              setTraderIdFilter(newValue || '');
            }}
            onInputChange={(event, newInputValue) => {
              setTraderIdFilter(newInputValue);
            }}
          />
        </Box>

        {loading ? (
          <Box
            alignItems='center'
            display='flex'
            flex={1}
            justifyContent='center'
          >
            <ScaleLoader color={theme.palette.text.offWhite} />
          </Box>
        ) : (
          <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledHeaderTableCellWithLine
                      align={column.align}
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        width: column.width || undefined,
                      }}
                    >
                      {column.label}
                    </StyledHeaderTableCellWithLine>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ overflow: 'auto' }}>
                {proofs
                  .filter(
                    (proof) =>
                      !traderIdFilter ||
                      matchesTraderId(traderIdFilter, proof.traderId)
                  )
                  .sort((a, b) => b.blockNumber - a.blockNumber)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((tx) => {
                    return (
                      <TableRow
                        hover
                        key={tx.transactionHash}
                        sx={{
                          height: '55.38px',
                        }}
                      >
                        {columns.map((column) => {
                          const value = tx[column.id];
                          switch (column.id) {
                            case 'eventName':
                              return (
                                <StyledTableCell
                                  align={column.align}
                                  key={column.id}
                                >
                                  <Chip
                                    color={tx.eventColor}
                                    label={value}
                                    size='small'
                                  />
                                </StyledTableCell>
                              );
                            case 'traderId':
                              return displayDefaultTableCell(
                                column,
                                insertEllipsis(value),
                                {},
                                StyledTableCell
                              );
                            case 'data':
                              return (
                                <StyledTableCell
                                  align={column.align}
                                  key={column.id}
                                >
                                  {tx.eventName === 'Risk' ? (
                                    `Volume: ${tx.data.toLocaleString()}`
                                  ) : (
                                    <>
                                      Merkle Hash:{' '}
                                      {insertEllipsis(tx.data.merkleRoot, 8, 6)}
                                      <br />
                                      CID: {insertEllipsis(tx.data.cid, 8, 6)}
                                    </>
                                  )}
                                </StyledTableCell>
                              );
                            case 'attester':
                              return displayDefaultTableCell(
                                column,
                                insertEllipsis(value),
                                {},
                                StyledTableCell
                              );
                            case 'transactionHash':
                              return (
                                <StyledTableCell
                                  align={column.align}
                                  key={column.id}
                                >
                                  <Link
                                    href={`https://basescan.org/tx/${value}`}
                                  >
                                    {insertEllipsis(value, 8, 6)}
                                  </Link>
                                </StyledTableCell>
                              );
                            case 'blockNumber':
                              return displayDefaultTableCell(
                                column,
                                value.toLocaleString(),
                                {},
                                StyledTableCell
                              );
                            case 'actions':
                              return (
                                <StyledTableCell
                                  align={column.align}
                                  key={column.id}
                                >
                                  <Button
                                    size='small'
                                    variant='text'
                                    onClick={() =>
                                      navigate(
                                        `/explorer/proofs/${tx.transactionHash}`
                                      )
                                    }
                                  >
                                    View details
                                  </Button>
                                </StyledTableCell>
                              );
                            default:
                              return displayDefaultTableCell(
                                column,
                                value,
                                {},
                                StyledTableCell
                              );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box sx={{ flexShrink: 0, mt: 2 }}>
          {' '}
          {/* flexShrink: 0 prevents shrinking */}
          {pageView ? (
            <Button
              sx={{
                width: '100%',
                height: '50px',
                border: 0,
                color: theme.palette.text.offWhite,
                borderColor: theme.palette.text.offWhite,
              }}
              variant='outlined'
              onClick={() => navigate('/explorer/proofs')}
            >
              View all proofs
            </Button>
          ) : (
            <TablePagination
              component='div'
              count={-1}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10]}
              sx={{ height: '60px' }}
              onPageChange={handleChangePage}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
